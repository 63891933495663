<template>
	<div class="setup app_flex" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box;' : ''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="设置" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>

		<div class="setup_body app_scroll mt12">
			<div class="setup_list">
				<span>方案信息同步</span>
				<van-switch v-model="MyInfo.isSync" size="0.5866rem" active-color="#02E265" inactive-color="#E6E6E6" @update:model-value="fUpdateSync" />
			</div>
			<div v-if="!MyInfo.hasPassword" class="setup_list_a" @click="fSetupPw">
				<span>登录密码</span>
				<div>
					<span>未设置</span>
					<img src="~@/assets/icon/icon-007.png">
				</div>
			</div>
			<div class="setup_list" @click="fModifyPw">
				<span>修改密码</span>
				<img src="~@/assets/icon/icon-007.png">
			</div>
			<div class="setup_list" @click="fGoAbout">
				<span>关于</span>
				<img src="~@/assets/icon/icon-007.png">
			</div>
			<div v-if="NODE_ENV === 'development'" class="setup_list" @click="fGoTest">
				<span>测试</span>
				<img src="~@/assets/icon/icon-007.png">
			</div>
		</div>

		<div class="setup_bottom">
			<van-button round block native-type="submit" class="app_button" @click="fLogout">
				退出登录
			</van-button>
		</div>


		<h-dialog-a dialogKey="dialogA" :show="oDialogA.showDialog" :showCancelButton="oDialogA.showCancelButton"
			:showConfirmButton="oDialogA.showConfirmButton" @fBeforeClose="fBeforeClose">
			<div class="setup_tips">
				<p>您确定现在要退出吗？</p>
			</div>
		</h-dialog-a>
	</div>
</template>

<script>
	import {
		reactive
	} from 'vue';
	import {
		useRouter
	} from "vue-router";
	import {
		useStore
	} from "vuex";
	import {
		Toast
	} from 'vant';
	import {
		apiSyncRolePlan
	} from '@/api/useInfo.js';
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		uaFun
	} from "@/utils/public.js";
	import HDialogA from "@/components/HDialog/HDialogA/index.vue";
	export default {
		name: "Setup",
		components: {
			HDialogA
		},
		setup() {
			const isApp = uaFun()
			const router = useRouter(); //初始化路由
			const store = useStore(); // 使用useStore方法
			const MyInfo = store.state.userInfo.myInfo;
			console.log(MyInfo)
			const NODE_ENV = process && process.env && process.env.NODE_ENV ? process.env.NODE_ENV : "production";

			// 方案信息同步
			const fUpdateSync = (newValue) => {
				Toast({
					type: "loading"
				});
				apiSyncRolePlan({
					isSync: newValue,
					roleId: MyInfo.roleId
				}).then(res => {
					Toast({
						message: res && res.message ? res.message : "操作成功",
						position: 'bottom'
					});
					store.dispatch("userInfo/UUserInfo", MyInfo)
				}).catch(() => {
					MyInfo.isSync = MyInfo.isSync ? false : true;
					store.dispatch("userInfo/UUserInfo", MyInfo)
				})
			};

			// oDialogA
			const oDialogA = reactive({
				showDialog: false,
				showCancelButton: true,
				showConfirmButton: true,
			});
			// 用户登出
			const fLogout = () => {
				oDialogA.showDialog = true;
			};
			// 弹框关闭之前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				console.log(action, oDialog, fn);
				if (dialogKey === "dialogA") {
					if (action === "confirm") {
						store.dispatch("userInfo/LogOut").then(() => {
							router.push({
								path: "/"
							});
						})
						oDialogA.showDialog = false;
						fn(false)
					} else if (action === "cancel") {
						oDialogA.showDialog = false;
						fn(true)
					}
				}
			};

			// 设置密码
			const fSetupPw = () => {
				router.push({
					path: "/setupPw"
				})
			};
			// 修改密码
			const fModifyPw = () => {
				router.push({
					path: "/modifyPw"
				})
			};
			// 关于
			const fGoAbout = () => {
				router.push({
					path: "/about"
				})
			};
			// 测试
			const fGoTest = () => {
				router.push({
					path: "/test"
				})
			};

			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/loginPw");
			};
			return {
				MyInfo,
				fUpdateSync,

				oDialogA,
				fLogout,
				fBeforeClose,

				fSetupPw,
				fModifyPw,
				fGoAbout,
				fGoTest,

				fLeft,
				isApp,
				NODE_ENV
			}
		}
	}
</script>

<style scoped lang="scss">
	.setup {
		width: 100%;
		height: 100%;
		box-sizing: border-box;

		.setup_body {
			padding: 0 0.72rem 0.5333rem;

			.setup_list {
				padding: 0.48rem 0;
				border-bottom: 0.0266rem solid #F5F5F5;

				display: flex;
				align-items: center;
				justify-content: space-between;

				&>span {
					font-size: 0.3733rem;
					font-weight: 400;
					color: #383838;

				}

				&>img {
					width: 0.16rem;
					height: auto;
				}
			}

			.setup_list_a {
				padding: 0.48rem 0;
				border-bottom: 0.0266rem solid #F5F5F5;

				display: flex;
				align-items: center;
				justify-content: space-between;

				&>span {
					font-size: 0.3733rem;
					font-weight: 400;
					color: #383838;

				}

				&>div {
					display: flex;
					align-items: center;

					&>p {
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
					}

					&>img {
						margin-left: 0.2133rem;
						width: 0.16rem;
						height: auto;
					}
				}

			}
		}

		.setup_bottom {
			padding: 0.4266rem;
		}

		.setup_tips {
			text-align: center;
			font-size: 0.4266rem;
			font-weight: 400;
			color: #383838;
			line-height: 0.5866rem;
			margin-bottom: 0.8rem;
		}
	}
</style>
