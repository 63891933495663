<template>
	<div class="capitalFrozen app_flex" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box;' : ''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="冻结资金" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="capitalFrozen_body">
			<van-tabs v-model:active="active" swipeable title-active-color="#383838" title-inactive-color="#979797"
				@rendered="fOnRendered">
				<van-tab title="出票中" name="1">
					<div v-if="oTicket.list.length > 0" class="capitalFrozen_tab_content">
						<van-list v-model:loading="oTicket.loading" v-model:error="oTicket.error"
							error-text="请求失败，点击重新加载" :finished="oTicket.finished" finished-text="没有更多了"
							@load="fOnLoadTicket">
							<div class="capitalFrozen_body_item" v-for="(item,index) in oTicket.list" :key="index">
								<div class="capitalFrozen_body_item_a">
									<div class="capitalFrozen_body_item_a_a">
										<p class="capitalFrozen_body_item_a_a_a">出票中</p>
										<p class="capitalFrozen_body_item_a_a_b">
											￥{{fnKeepFigures(item.amount)}}
										</p>
									</div>

									<div class="capitalFrozen_body_item_a_b">
										<p>{{fnFormatData(item.updated,"yyyy-MM-dd hh:mm")}}</p>
									</div>
								</div>
								<p class="capitalFrozen_body_item_b">编号：{{item.voucher}}</p>
							</div>
						</van-list>
					</div>
					<div v-else class="capitalFrozen_tab_content">
						<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')"
							description="目前没有出票中冻结记录……" />
					</div>
				</van-tab>
				<van-tab title="提现" name="2">
					<div v-if="oWithdrawal.list.length > 0" class="capitalFrozen_tab_content">
						<van-list v-model:loading="oWithdrawal.loading" v-model:error="oWithdrawal.error"
							error-text="请求失败，点击重新加载" :finished="oWithdrawal.finished" finished-text="没有更多了"
							@load="fOnLoadWithdrawal">
							<div class="capitalFrozen_body_item" v-for="(item,index) in oWithdrawal.list" :key="index">
								<div class="capitalFrozen_body_item_a">
									<div class="capitalFrozen_body_item_a_a">
										<p class="capitalFrozen_body_item_a_a_a">提现</p>
										<p class="capitalFrozen_body_item_a_a_b">
											￥{{fnKeepFigures(item.amount)}}
										</p>
									</div>
							
									<div class="capitalFrozen_body_item_a_b">
										<p>{{fnFormatData(item.updated,"yyyy-MM-dd hh:mm")}}</p>
									</div>
								</div>
								<p class="capitalFrozen_body_item_b">编号：{{item.voucher}}</p>
							</div>
						</van-list>
					</div>
					<div v-else class="capitalFrozen_tab_content">
						<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')"
							description="目前没有提现冻结记录……" />
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
	import {
		ref,
		reactive
	} from "vue";
	import {
		useStore
	} from "vuex";
	import {
		apiFrozenCapital
	} from "@/api/mine.js";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnFormatData,
		fnKeepFigures,
		uaFun
	} from "@/utils/public.js";
	export default {
		name: "CapitalFrozen",
		setup() {
			const isApp = uaFun()
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			const active = ref("1");
			// 方案--出票中
			const oTicket = reactive({
				current: 0,
				size: 10,
				list: [],
				error: false,
				loading: false,
				finished: false
			});
			// 加载--出票中
			const fOnLoadTicket = () => {
				const current = oTicket.current + 1;
				apiFrozenCapital({
					roleId: UserInfo.roleId,
					userId: UserInfo.userId,
					freezeType: "1",
					current,
					size: oTicket.size
				}).then(res => {
					oTicket.current = current;
					let tData = res && res.result ? res.result : {};
					if (tData.frozenCapitalOutticketList.records.length > 0) {
						oTicket.list.push(...tData.frozenCapitalOutticketList.records);
						if (tData.frozenCapitalOutticketList.hasNext) {
							oTicket.finished = false;
						} else {
							oTicket.finished = true;
						}
					} else {
						oTicket.finished = true;
					}
					oTicket.loading = false;
				}).catch(() => {
					oTicket.loading = false;
					oTicket.error = true;
				})
			};
			// 方案--提现
			const oWithdrawal = reactive({
				current: 0,
				size: 10,
				list: [],
				error: false,
				loading: false,
				finished: true
			});
			// 加载--提现
			const fOnLoadWithdrawal = () => {
				const current = oWithdrawal.current + 1;
				apiFrozenCapital({
					roleId: UserInfo.roleId,
					userId: UserInfo.userId,
					freezeType: "2",
					current,
					size: oWithdrawal.size
				}).then(res => {
					oWithdrawal.current = current;
					let tData = res && res.result ? res.result : {};
					if (tData.frozenCapitalOutticketList.records.length > 0) {
						oWithdrawal.list.push(...tData.frozenCapitalOutticketList.records);
						if (tData.frozenCapitalOutticketList.hasNext) {
							oWithdrawal.finished = false;
						} else {
							oWithdrawal.finished = true;
						}
					} else {
						oWithdrawal.finished = true;
					}
					oWithdrawal.loading = false;
				}).catch(() => {
					oWithdrawal.loading = false;
					oWithdrawal.error = true;
				})
			};
			const fOnRendered = (Name) => {
				if (Name === "1") {
					oTicket.finished = false;
				} else if (Name === "2") {
					fOnLoadWithdrawal();
					oWithdrawal.finished = false;
				}
			}
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			
			fOnLoadTicket();
			return {
				active,
				oTicket,
				oWithdrawal,

				fOnLoadTicket,
				fOnLoadWithdrawal,

				fOnRendered,
				fnFormatData,
				fnKeepFigures,
				fLeft,
				isApp
			}
		}
	}
</script>

<style scoped lang="scss">
	.capitalFrozen {
		width: 100%;
		height: 100%;
		background: #F1F2F7;

		.capitalFrozen_body {
			flex: 1;
			overflow: hidden;
			.capitalFrozen_tab_content {
				height: 100%;
				overflow-x: auto;
				padding: 0.32rem 0.32rem 1.36rem;
			}

			.capitalFrozen_body_item {
				padding: 0.4rem 0.4rem 0;
				margin-bottom: 0.32rem;

				background: #FFFFFF;
				box-shadow: 0px 0.0533rem 0.2133rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.2133rem;

				.capitalFrozen_body_item_a {
					border-bottom: 0.0266rem solid #EAEAEA;

					.capitalFrozen_body_item_a_a {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.capitalFrozen_body_item_a_a_a {
							font-size: 0.3733rem;
							font-weight: 500;
							color: #383838;
							line-height: 0.5333rem;
						}

						.capitalFrozen_body_item_a_a_b {
							font-size: 0.4266rem;
							font-weight: bold;
							color: #383838;
							line-height: 0.5066rem;
						}
					}
				}

				.capitalFrozen_body_item_a_b {
					display: flex;
					margin-top: 0.16rem;
					margin-bottom: 0.16rem;
					&>p {
						font-size: 0.32rem;
						font-weight: 400;
						color: #979797;
						line-height: 0.4533rem;
					}
				}

				.capitalFrozen_body_item_b {
					padding: 0.2133rem 0;
					font-size: 0.32rem;
					font-weight: 400;
					color: #979797;
					line-height: 0.4533rem;
				}
			}
		}
	}
</style>
