/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
	return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
	const valid_map = ['admin', 'editor']
	return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
	const reg =
		/^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
	return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
	const reg = /^[a-z]+$/
	return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
	const reg = /^[A-Z]+$/
	return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
	const reg = /^[A-Za-z]+$/
	return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
	const reg =
		/^(([^<>()[]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
	if (typeof str === 'string' || str instanceof String) {
		return true
	}
	return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
	if (typeof Array.isArray === 'undefined') {
		return Object.prototype.toString.call(arg) === '[object Array]'
	}
	return Array.isArray(arg)
}

/**
 * @param {Object} obj
 * @returns {Boolean}
 */
export function fnIsObject(obj) {
	return Object.prototype.toString.call(obj) === '[object Object]'
}

/**
 * [isMobile  手机验证:只验证1开头11位数字]
 * @param  {[string,number]} str [ 字符串 ]
 * @return {Boolean}      [布尔值]
 */

export function isMobile(str) {
	return /^1[0-9]{10}$/.test(str);
}

/**
 * [fnValidStrLength  长度验证]
 * @param  {[string,number]} str [ '' ]
 * @param  {[number]} min [ 字符串长度最小值6 ]
 * @param  {[number]} max [ 字符串长度最大值16 ]
 * @return {Boolean}      [布尔值]
 */

export function fnValidStrLength(str, min = 6, max = 16) {
	if (min <= str.length && str.length <= max) {
		return true
	} else {
		return false
	}
}

/**
 * [fnIsEN 是否为数字英文]
 * @param  {[String]} str [输入对象]
 * @return {[Boolean]}     [Boolean]
 */
export function fnIsEN(str) {
	return /^[A-Za-z0-9]+$/gi.test(str);
}

/**
 * [isChinese 是否为中文名称]
 * @param  {[type]}  str [description]
 * @return {Boolean}     [description]
 */
export function isChineseName(str) {
	return /^([\u4E00-\u9FA5]|[\uFE30-\uFFA0]{2,5})+([·|•]([\u4E00-\u9FA5]|[\uFE30-\uFFA0]){2,5})*$/gi.test(str);
}
/**
 * [isIdCard 身份证验证]
 * @param {[type]} gets [身份证号]
 * @return {Boolean}     [布尔值]
 */
export function validIdCard(gets) {
	let Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1]; // 加权因子;
	let ValideCode = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2]; // 身份证验证位值，10代表X;
	if (gets.length == 15) {
		return isValidityBrithBy15IdCard(gets);
	} else if (gets.length == 18) {
		let a_idCard = gets.split(""); // 得到身份证数组
		if (isValidityBrithBy18IdCard(gets) && isTrueValidateCodeBy18IdCard(a_idCard)) {
			return true;
		}
		return false;
	}
	return false;

	function isTrueValidateCodeBy18IdCard(a_idCard) {
		let sum = 0; // 声明加权求和变量
		if (a_idCard[17].toLowerCase() == 'x') {
			a_idCard[17] = 10; // 将最后位为x的验证码替换为10方便后续操作
		}
		for (let i = 0; i < 17; i++) {
			sum += Wi[i] * a_idCard[i]; // 加权求和
		}
		let valCodePosition = sum % 11; // 得到验证码所位置
		if (a_idCard[17] == ValideCode[valCodePosition]) {
			return true;
		}
		return false;
	}

	function isValidityBrithBy18IdCard(idCard18) {
		let year = idCard18.substring(6, 10);
		let month = idCard18.substring(10, 12);
		let day = idCard18.substring(12, 14);
		let temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
		// 这里用getFullYear()获取年份，避免千年虫问题
		if (temp_date.getFullYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date
			.getDate() !=
			parseFloat(day)) {
			return false;
		}
		return true;
	}

	function isValidityBrithBy15IdCard(idCard15) {
		let year = idCard15.substring(6, 8);
		let month = idCard15.substring(8, 10);
		let day = idCard15.substring(10, 12);
		let temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
		// 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
		if (temp_date.getYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date
			.getDate() !=
			parseFloat(day)) {
			return false;
		}
		return true;
	}
}

/**
 * [validPassword  密码验证]
 * @param  {[string,number]} str [ '' ]
 * @param  {[number]} min [ 字符串长度最小值6 ]
 * @param  {[number]} max [ 字符串长度最大值16 ]
 * @return {Boolean}      [布尔值]
 */

export function validPassword(str, min = 6, max = 16) {
	if (min <= str.length && str.length <= max) {
		return true
	} else {
		return false
	}
}
/**
 * [isMobile  为空验证]
 * @param  {[string,number]} str [ 字符串 ]
 * @return {Boolean}      [布尔值]


export function isMobile(str) {
	if(!str && ){
		
	}
	return /^1[3-9][0-9]{9}$/.test(str);
}
 */
