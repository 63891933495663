import request from '@/utils/request';
import consts from '@/utils/consts';

// 个人中心：获取用户信息(包含点头像进去的个人信息)
export function apiGetUserInfo(data) {
	return request({
		url: consts.businessPublic + '/user/getUserInfo',
		method: 'post',
		data: data
	})
}
//关注
export function focusOn(data) {
	return request({
		url: consts.businessPublic + '/focus/focusOn',
		method: 'post',
		data: data
	})
}
//取消关注
export function focusCancel(data) {
	return request({
		url: consts.businessPublic + '/focus/focusCancel',
		method: 'post',
		data: data
	})
}

//关注
export function focusList(data) {
	return request({
		url: consts.businessPublic + '/focus/queryMemberFocusInfosForPage',
		method: 'post',
		data: data
	})
}

// 查询是否有未读消息
export function apiGetNoticeHasUnread(data) {
	return request({
		url: consts.businessPublic + '/notice/hasUnread',
		method: 'post',
		data: data
	})
}
// 分页获取消息通知
export function apiGetNoticeOnePage(data) {
	return request({
		url: consts.businessPublic + '/notice/getNoticeOnePage',
		method: 'post',
		data: data
	})
}
// 批量标记为已读
export function apiReadMarkBatch(data) {
	return request({
		url: consts.businessPublic + '/notice/readMarkBatch',
		method: 'post',
		data: data
	})
}

// 账户充值前置
export function apiAccountPreRecharge(data) {
	return request({
		url: consts.businessPublic + '/account/preRecharge',
		method: 'post',
		data: data
	})
}

// 账户充值
export function apiAccountRecharge(data) {
	return request({
		url: consts.businessPublic + '/account/recharge',
		method: 'post',
		data: data
	})
}

// 账户充值-2.0
export function apiAccountRechargeNew(data) {
	return request({
		url: consts.businessPublic + '/account/rechargeNew',
		method: 'post',
		data: data
	})
}

// 银行列表
export function apiAccountFindBanks(data) {
	return request({
		url: consts.businessPublic + '/account/findBanks',
		method: 'post',
		data: data
	})
}

// 账号提现所需数据
export function apiAccountPreWithdrawal(data) {
	return request({
		url: consts.businessPublic + '/account/preWithdrawal',
		method: 'post',
		data: data
	})
}

// 计算实际到账金额
export function apiAccountCalculate(data) {
	return request({
		url: consts.businessPublic + '/account/calculate',
		method: 'post',
		data: data
	})
}

// 提现
export function apiAccountWithdrawal(data) {
	return request({
		url: consts.businessPublic + '/account/withdrawal',
		method: 'post',
		data: data
	})
}


// 个人中心：获取转到余额提示信息
export function apiTurnBalance(data) {
	return request({
		url: consts.businessPublic + '/user/turnBalance',
		method: 'post',
		data: data
	})
}

// 个人中心：待付佣金：立即支付
export function apiImmediatelyPay(data) {
	return request({
		url: consts.businessPublic + '/user/immediatelyPay',
		method: 'post',
		data: data
	})
}

// 个人中心-查询待转佣金详情
export function apiGetUntransferBalanceDetail(data) {
	return request({
		url: consts.businessPublic + '/plan/getUntransferBalanceDetail',
		method: 'post',
		data: data
	})
}
// 个人中心-查询待提佣金列表
export function apiGetUntransferBalanceOnePage(data) {
	return request({
		url: consts.businessPublic + '/plan/getUntransferBalanceOnePage',
		method: 'post',
		data: data
	})
}



// 个人中心列表：资金明细
export function apiCapitalDetail(data) {
	return request({
		url: consts.businessPublic + '/user/capitalDetail',
		method: 'post',
		data: data
	})
}
// 个人中心：店内余额-列表
export function apiAvailableCapital(data) {
	return request({
		url: consts.businessPublic + '/user/availableCapital',
		method: 'post',
		data: data
	})
}
// 个人中心列表：冻结资金
export function apiFrozenCapital(data) {
	return request({
		url: consts.businessPublic + '/user/frozenCapital',
		method: 'post',
		data: data
	})
}


// 个人中心-查询跟单-统计信息
export function apiGetPlanDocumentaryRecordDetail(data) {
	return request({
		url: consts.businessPublic + '/plan/getPlanDocumentaryRecordDetail',
		method: 'post',
		data: data
	})
}
// 个人中心-查询跟单-记录列表
export function apiGetPlanDocumentaryRecordOnePage(data) {
	return request({
		url: consts.businessPublic + '/plan/getPlanDocumentaryRecordOnePage',
		method: 'post',
		data: data
	})
}


// 个人中心-查询发单-统计信息
export function apiGetPlanBillRecordDetail(data) {
	return request({
		url: consts.businessPublic + '/plan/getPlanBillRecordDetail',
		method: 'post',
		data: data
	})
}
// 个人中心-查询发单-记录列表
export function apiGetPlanBillRecordOnePage(data) {
	return request({
		url: consts.businessPublic + '/plan/getPlanBillRecordOnePage',
		method: 'post',
		data: data
	})
}
// 个人中心-查询发单-记录列表-跟单详情
export function apiGetPlanBillDocumentaryDetail(data) {
	return request({
		url: consts.businessPublic + '/plan/getPlanBillDocumentaryDetail',
		method: 'post',
		data: data
	})
}
// 个人中心-查询发单-记录列表-跟单详情-记录列表
export function apiGetPlanBillDocumentaryOnePage(data) {
	return request({
		url: consts.businessPublic + '/plan/getPlanBillDocumentaryOnePage',
		method: 'post',
		data: data
	})
}