// empty+变量 ：empty为空
// format+变量 ：format格式验证
// diff+变量 ：diff差异验证
// max+变量 ：max最大限制
export default {
	"success": "操作成功",
	"sentMessage": "短信已发送，请注意查收",
	"emptyPhone": "请输入手机号码",
	"formatPhone": "手机号码格式不正确",
	"getCaptcha": "请先获取验证码",
	"emptyCaptcha": "请输入验证码",
	"emptyPassword": "请输入登录密码",
	"emptyPasswordOld": "请输入原密码",
	"emptyPasswordSame": "请输入确认密码",
	"formatPassword": "密码格式不正确",
	"diffPassword": "两次密码不一致",
	"samePassword": "不能与原密码一致",
	"requireShop":"必须绑定一家彩票店",
	"emptyRealName":"请输入真实姓名",
	"formatRealName":"请输入中文",
	"emptyIDnumber":"请输入正确的身份证号",
	"formatIDnumber":"身份证号格式不正确",
	"emptyRoleName":"请输入角色名称",
	"formatRoleName":"请输入2-8个字符",
	"emptyMoney":"请选择金额",
	"emptyPayWay":"请选择支付方式",
	"disagreeRead": "请先阅读并同意",
	"emptyAccount":"请输入账号",
	"emptyBankAccount":"请输入银行卡号",
	"emptyBankName":"请选择银行",
	"emptyBankCardOpen":"请输入开户行",
	"emptyBankCardName":"请输入开户姓名",
	"emptyWithdrawalMoney":"请输入提现金额",
	"maxSession":"最大可选八场比赛",
	"copySuc":"复制成功！",
	"copyFail":"复制失败！"
}
