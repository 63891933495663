<template>
	<div class="recharge" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box;' : ''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="充值" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>

		<div class="recharge_body">
			<div class="recharge_body_a">
				<div class="recharge_body_a_a">选择金额：</div>
				<div class="recharge_body_a_b">
					<p v-for="(item,index) in aMoneyList" :key="index"
						:class="{'recharge_body_a_b_select' : !money_input && item === money_select}"
						@click="fSelect(item)">
						<em>￥</em>{{item}}
					</p>
				</div>
			</div>

			<div class="recharge_body_b mt16">
				<div class="recharge_body_b_a">
					<span>自定义金额：</span>
				</div>

				<div class="recharge_body_b_b">
					<span class="recharge_body_b_b_a">￥</span>
					<van-field class="recharge_body_b_b_b" type="digit" v-model="money_input" placeholder="请输入充值金额" clearable
						maxlength="10" @blur="fBlurMoney" />
				</div>
			</div>

			<div class="recharge_body_c mt16">
				<p class="recharge_body_c_title">支付方式：</p>
				<van-radio-group v-model="typeChecked" checked-color="#FF7671">
					<van-cell-group>
						<!-- <van-cell clickable @click="typeChecked = '1'">
							<template #title>
								<div class="recharge_body_c_a">
									<img src="~@/assets/images/img_024.png">
									<span>微信支付</span>
								</div>
							</template>
							<template #right-icon>
								<van-radio name="1" />
							</template>
						</van-cell> -->
						<van-cell clickable @click="typeChecked = '2'">
							<template #title>
								<div class="recharge_body_c_a">
									<img src="~@/assets/images/img_022.png">
									<span>支付宝</span>
								</div>
							</template>
							<template #right-icon>
								<van-radio name="2" />
							</template>
						</van-cell>
					</van-cell-group>
				</van-radio-group>
			</div>

			<!-- <div class="recharge_body_d">
				<p>
					最低充值金额
					<em class="cE2335D">10元</em>，
					最高充值金额
					<em class="cE2335D">10000元</em>
				</p>
				<p>当前总充值金额 <em class="cE2335D">10000元</em></p>
			</div> -->

			<div class="recharge_body_e" v-html="fnFormatColor(oCPData.oNCData.minimumText)">
			</div>
		</div>

		<div class="recharge_bottom">
			<van-button round block native-type="submit" class="app_button" :disabled="oCPData.oYCData.isDisabled"
				@click="fSubmit">
				充值
			</van-button>
		</div>

		<div id="payZFB" v-html="ZFBDOM"></div>
	</div>
</template>

<script>
	import {
		ref,
		reactive
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter,
		onBeforeRouteLeave
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnClearNoInteger,
		fnFormatColor,
		uaFun
	} from "@/utils/public.js";
	import tipsContent from '@/utils/tipsContent';
	import {
		apiAccountRechargeNew,
		apiAccountPreRecharge
	} from '@/api/mine.js';
	export default {
		name: "Recharge",
		setup() {
			const isApp = uaFun()
			const route = useRoute();
			const router = useRouter();
			const route_query = route.query;
			//离开当前的组件，触发
			onBeforeRouteLeave((to) => {
				console.log(to.path);
				if (to.path !== "/web" && to.path !== "/redirect" && route_query.redirect && to.path !== ("/" + route_query.redirect)) {
					router.replace({
						name: 'Redirect',
						params: {
							path: route_query.redirect
						}
					});
					return false;
				}
			});
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			// 选择的支付方式
			const typeChecked = ref("2");
			// 充值金额--输入的充值金额
			const money_input = ref("");
			// 充值金额--选择的充值金额
			const money_select = ref("10");
			// 充值金额--历史选择的充值金额
			const money_selectOld = ref("10");
			// 可选择金额数组
			const aMoneyList = ["10", "50", "200", "1000", "5000", "10000"];
			// 支付宝回调的DOM节点
			const ZFBDOM = ref("");
			// 页面数据
			const oCPData = reactive({
				oNCData: {
					minimum: null,
					minimumText: null
				},
				oYCData: {
					isDisabled: false
				}
			});
			// 获取页面数据
			const fGetCPData = () => {
				Toast({
					type: "loading"
				});
				apiAccountPreRecharge({
					roleId: UserInfo.roleId
				}).then(res => {
					let tData = res.result ? res.result : {};
					oCPData.oNCData.minimum = tData.minimum;
					oCPData.oNCData.minimumText = tData.minimumText;
				})
			};
			fGetCPData();
			// 选择金额
			const fSelect = (Money) => {
				money_select.value = Money;
				money_selectOld.value = Money;
				money_input.value = "";
			};
			// 充值金额--失焦
			const fBlurMoney = () => {
				const Digit = fnClearNoInteger(money_input.value);
				money_input.value = "";
				money_input.value = Digit;
				// 如果输入金额为空则取消选中金额
				if (Number(money_input.value) > 0) {
					money_select.value = "";
				} else {
					money_select.value = money_selectOld.value;
				}
			};
			// 充值
			const fSubmit = () => {
				// 是否选择支付方式
				if (!typeChecked.value) {
					return Toast({
						message: tipsContent.emptyPayWay,
						position: 'bottom'
					});
				}

				Toast({
					type: "loading"
				});
				oCPData.oYCData.isDisabled = true;
				apiAccountRechargeNew({
					amount: money_input.value || money_select.value,
					flag: "WAP",
					payType: typeChecked.value,
					roleId: UserInfo.roleId,
					userId: UserInfo.userId
				}).then(res => {
					console.log(res);
					let tData = res.result ? res.result : {};
					let tRechargeWay = tData.rechargeWay ? tData.rechargeWay : "QRCODE";
					let tRechargeResult =  tData.rechargeResult ? tData.rechargeResult : "";
					if (tRechargeWay === "QRCODE") {
						router.push({
							path: '/web',
							query: {
								type: "recharge",
								url: tRechargeResult
							}
						});
					} else {
						// document.body.innerHTML = tRechargeResult;
						// document.forms['alipaysubmit'].submit();

						ZFBDOM.value = tRechargeResult;
						// window.open(ZFBDOM.value);
						location.href = ZFBDOM.value;
						// 要先定义打开新窗口，否则也会被拦截  不生效
						// const winRef = window.open("about:blank","_blank");
						// winRef.location = ZFBDOM.value;
						oCPData.oYCData.isDisabled = false;
					}
				}).catch(() => {
					oCPData.oYCData.isDisabled = false;
				})
			};
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};



			return {
				typeChecked,
				money_input,
				money_select,
				aMoneyList,
				ZFBDOM,
				oCPData,

				fSelect,
				fBlurMoney,
				fLeft,
				fSubmit,
				isApp,
				fnFormatColor
			}
		}
	}
</script>

<style scoped lang="scss">
	#payZFB {
		display: none;
	}

	.recharge {
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		box-sizing: border-box;

		.recharge_body {
			margin: 0.32rem 0.2933rem 0;

			.recharge_body_a {
				background: #FFFFFF;
				border-radius: 0.2133rem;
				padding: 0.4266rem;

				.recharge_body_a_a {
					font-size: 0.4266rem;
					font-weight: 400;
					color: #383838;
					margin-bottom: 0.4266rem;

				}

				.recharge_body_a_b {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-items: center;

					&>p {
						width: 2.6666rem;
						height: 1.6533rem;
						background: rgba(255, 118, 113, 0.1);
						border-radius: 0.1066rem;
						border: 0.0266rem solid #FF7671;
						text-align: center;
						line-height: 1.6533rem;
						// margin-right: 0.2666rem;

						font-size: 0.6933rem;
						font-weight: bold;
						color: #E2335D;

						&>em {
							font-size: 0.3733rem;
							font-weight: bold;
							color: #E2335D;
						}
					}

					&>p:nth-child(n+4) {
						margin-top: 0.2666rem;
					}

					.recharge_body_a_b_select {
						color: #FFFFFF;
						background: linear-gradient(0deg, #E2335D 0%, #FF7671 100%);
						border: 0.0266rem solid #FF7671;

						&>em {
							color: #FFFFFF;
						}
					}
				}
			}

			.recharge_body_b {
				padding: 0.4266rem;
				background: #FFFFFF;
				border-radius: 0.2133rem;

				.recharge_body_b_a {
					font-size: 0.4266rem;
					font-weight: 400;
					color: #383838;
				}

				.recharge_body_b_b {
					display: flex;
					align-items: center;
					margin-top: 0.4266rem;

					.recharge_body_b_b_a {
						font-size: 0.6933rem;
						font-weight: bold;
						color: #383838;
						margin: 0 0.2133rem 0 0;
					}

					.recharge_body_b_b_c {
						font-size: 0.32rem;
						font-weight: 500;
						color: #E2335D;
						margin-left: 0.4266rem;
					}
				}

				.recharge_body_c_c {
					margin-top: 0.2933rem;
					margin-left: 0.8533rem;

					font-size: 0.3733rem;
					font-weight: 400;
					color: #979797;
				}
			}

			.recharge_body_c {
				background: #FFFFFF;
				border-radius: 0.2133rem;
				padding-bottom: 0.4266rem;

				.recharge_body_c_title {
					padding: 0.4266rem;
					font-size: 0.4266rem;
					font-weight: 400;
					color: #383838;
				}

				.recharge_body_c_a {
					display: flex;
					align-items: center;

					&>img {
						width: 0.96rem;
						height: auto;
						border-radius: 0.1066rem;
					}

					&>span {
						margin-left: 0.4266rem;
						font-size: 0.4266rem;
						font-weight: 400;
						color: #383838;
					}
				}
			}

			.recharge_body_d {
				margin-top: 0.32rem;
				font-size: 0.32rem;
				font-weight: 400;
				color: #979797;

				&>p {
					margin-bottom: 0.2133rem;
				}
			}

			.recharge_body_e {
				margin-top: 0.32rem;
				white-space: break-spaces;
				word-break: break-all;
				font-size: 0.32rem;
				font-weight: 400;
				color: #979797;
				line-height: .48rem;
			}
		}

		.recharge_bottom {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 0.4266rem;
			background: #FFFFFF;
			border-radius: 0.2133rem 0.2133rem 0 0;
		}
	}
</style>
