import request from '@/utils/request';
import consts from '@/utils/consts';



// 查询投注内容
export function apiGetBetContent(data) {
	return request({
		url: consts.businessPublic + '/plan/getBetContent',
		method: 'post',
		data: data
	})
}

// 查询平台方案详情
export function apiGetPlatformPlanDetail(data) {
	return request({
		url: consts.businessPublic + '/plan/getPlatformPlanDetail',
		method: 'post',
		data: {
			...data,
			noToast: true
		}
	})
}


// 头像上传
export function apiUploadFile(data) {
	return request({
		url: consts.businessPublic + '/user/getUploadPortraitSignature',
		method: 'post',
		data: data
	})
}

// 查询充值方式
export function apiGetRechargeWay() {
	return request({
		url: consts.businessPublic + '/account/getRechargeWay',
		method: 'post'
	})
}

// 跟单详情消息列表
export function apiListComment(data) {
	return request({
		url: consts.businessPublic + '/plan/listComment',
		method: 'post',
		data: data
	})
}
// 跟单详情评论
export function apiSaveComment(data) {
	return request({
		url: consts.businessPublic + '/plan/saveComment',
		method: 'post',
		data: data
	})
}
// 消息回复详情
export function apiQueryReplyDetail(data) {
	return request({
		url: consts.businessPublic + '/plan/queryReplyDetail',
		method: 'post',
		data: data
	})
}
// 消息回复列表
export function apiListReply(data) {
	return request({
		url: consts.businessPublic + '/plan/listReply',
		method: 'post',
		data: data
	})
}

// 跟单详情评论点赞
export function apiSaveAgree(data) {
	return request({
		url: consts.businessPublic + '/plan/saveAgree',
		method: 'post',
		data: data
	})
}

// 查询点赞列表
export function apiListAgree(data) {
	return request({
		url: consts.businessPublic + '/plan/pageAgree',
		method: 'post',
		data: data
	})
}