<template>
	<div class="recordGD app_flex" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box;' : ''">
		<div :class="{'recordGD_zIndex' : oSearch.show}">
			<div class="app_title_b">
				<van-sticky>
					<van-nav-bar class="recordGD_bar" title="跟单记录" left-arrow @click-left="fLeft">
					</van-nav-bar>
				</van-sticky>
			</div>
			<div class="recordGD_HDropdownMenu">
				<div class="HDropdownMenu">
					<div class="HDropdownMenu_show"
						:class="{'HDropdownMenu_show--select' : oPlanStatus.select.name !== '全部'}"
						@click="fOpenPlanStatus">
						<span>{{oPlanStatus.select.name !== "全部" ? oPlanStatus.select.name : "方案状态" }}</span>
						<img v-if="oPlanStatus.select.name === '全部'" src="~@/assets/icon/icon-023.png">
						<img v-else src="~@/assets/icon/icon-026.png">
					</div>
					<div class="HDropdownMenu_content" v-if="oPlanStatus.show">
						<div class="HDropdownMenu_content_title" @click="fClosePlanStatus">
							<span>方案状态</span>
							<img src="~@/assets/icon/icon-024.png">
						</div>
						<p v-for="(item,index) in oPlanStatus.option" :key="index" class="HDropdownMenu_content_option"
							:class="{'HDropdownMenu_content_option--selected' : item.name === oPlanStatus.select.name}"
							@click="fSelectPlanStatus(item)">{{item.name}}</p>
					</div>
					<div class="HDropdownMenu_mask" v-if="oPlanStatus.show" @click="fClosePlanStatus"></div>
				</div>
				<div class="HDropdownMenu">
					<div class="HDropdownMenu_show"
						:class="{'HDropdownMenu_show--select' : oCommissionStatus.select.name !== '全部'}"
						@click="fOpenCommissionStatus">
						<span>{{oCommissionStatus.select.name !== "全部" ? oCommissionStatus.select.name : "佣金状态" }}</span>
						<img v-if="oCommissionStatus.select.name === '全部'" src="~@/assets/icon/icon-023.png">
						<img v-else src="~@/assets/icon/icon-026.png">
					</div>
					<div class="HDropdownMenu_content" v-if="oCommissionStatus.show">
						<div class="HDropdownMenu_content_title" @click="fCloseCommissionStatus">
							<span>佣金状态</span>
							<img src="~@/assets/icon/icon-024.png">
						</div>
						<p v-for="(item,index) in oCommissionStatus.option" :key="index"
							class="HDropdownMenu_content_option"
							:class="{'HDropdownMenu_content_option--selected' : item.name === oCommissionStatus.select.name}"
							@click="fSelectCommissionStatus(item)">{{item.name}}
						</p>
					</div>
					<div class="HDropdownMenu_mask" v-if="oCommissionStatus.show" @click="fCloseCommissionStatus"></div>
				</div>
				<div class="HDropdownMenu HDropdownMenu_search">
					<div class="HDropdownMenu_show"
						:class="{'HDropdownMenu_show--select' : oSearch.show || oSearch.value}" @click="fOpenSearch">
						<span> {{oSearch.show ? "发单者名称" : (oSearch.value ? oSearch.value : "发单者名称")}}</span>

						<img v-if="oSearch.show" src="~@/assets/icon/icon-024.png">
						<img v-else-if="oSearch.value" src="~@/assets/icon/icon-026.png">
						<img v-else src="~@/assets/icon/icon-023.png">
					</div>
				</div>
			</div>

			<div class="HDropdownMenu_search_content" v-if="oSearch.show">
				<van-search v-model="oSearch.inputVal" shape="round" show-action :clearable="false" background="#F1F2F7"
					placeholder="请输入发单者名称">
					<template #left-icon>
						<img class="HDropdownMenu_search_content_left-icon" src="~@/assets/icon/icon-025.png">
					</template>
					<template #action>
						<div class="HDropdownMenu_search_content_btn" @click="fSearch">筛选</div>
					</template>
				</van-search>


			</div>

		</div>
		<div v-if="oRecord.list.length > 0" class="mt12 recordGD_body app_scroll">
			<van-list v-model:loading="oRecord.loading" :finished="oRecord.finished" v-model:error="oRecord.error"
				error-text="请求失败，点击重新加载" finished-text="没有更多了" @load="fOnLoad">
				<div class="recordGD_body_card" v-for="(item,index) in oRecord.list" :key="index">
					<div class="recordGD_body_card_a">
						<div class="recordGD_body_card_a_a">
							<div v-if="item.lotteryType=='JCZQ'" class="recordGD_body_card_a_a_a--blue"></div>
							<div v-else class="recordGD_body_card_a_a_a--red"></div>
							<span class="recordGD_body_card_a_a_b">{{item.lotteryType=='JCZQ' ? "竞彩足球":"竞彩篮球"}}</span>
							<span class="recordGD_body_card_a_a_c">{{item.lotteryPlanSubType}}</span>
						</div>
						<div class="recordGD_body_card_a_b">
							佣金状态：<em>{{item.isCommission ? (item.commissionPayStatus ? '已支付' : '待支付') : '-'}}</em>
						</div>
					</div>

					<div class="recordGD_body_card_b">
						<div class="recordGD_body_card_b_a">
							<p class="recordGD_body_card_b_a_a">投注金额：<em>¥{{item.totalFee}}</em></p>
							<p class="recordGD_body_card_b_a_a">中奖金额：<em>¥{{item.afterTaxPrize}}</em></p>
							<p class="recordGD_body_card_b_a_b">发单者：<em>{{item.roleName}}</em></p>
						</div>
						<div class="recordGD_body_card_b_b">
							佣金：<em>¥{{item.isWinning ? item.commissionAmount : '0.00'}}</em>
						</div>
					</div>

					<div class="recordGD_body_card_c">
						<span
							class="recordGD_body_card_c_a">跟单时间：{{fnFormatData(item.created, "MM-dd hh:mm")}}</span>
						<span
							class="recordGD_body_card_c_a">{{item.isWinning ? fnRecordGDStatusCode(item.planStatus) : "未中奖"}}</span>
					</div>
				</div>
			</van-list>
		</div>
		<div v-else class="mt12 recordGD_body app_scroll">
			<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')"
				description="暂时没有数据" />
		</div>
		<div class="recordGD_bottom">
			<div class="recordGD_bottom_a">
				<p class="recordGD_bottom_a_a">待支付佣金 <em>{{oDetail.unpayCount}}</em> 笔</p>
				<p class="recordGD_bottom_a_b">合计 <em>{{oDetail.unpayFee}}</em> 元</p>
			</div>


			<van-button class="recordGD_bottom_b" color="linear-gradient(180deg, #FF7671 0%, #E2335D 100%)" round
				@click="fOpenPay()">
				支付佣金
			</van-button>
		</div>
		<div v-if="oSearch.show" class="HDropdownMenu_search_mask" @click="fCloseSearch"></div>
		<h-dialog-c title="支付成功" dialogKey="dialogD" :show="oCPData.oYCData.dialogD.showDialog"
			:showCancelButton="oCPData.oYCData.dialogD.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogD.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="recordGD_tips">
				跟单待付佣金已结清！
			</div>
		</h-dialog-c>
	</div>
</template>

<script>
	import {
		reactive
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter,
		onBeforeRouteLeave
	} from "vue-router";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		apiImmediatelyPay,
		apiGetPlanDocumentaryRecordDetail,
		apiGetPlanDocumentaryRecordOnePage
	} from '@/api/mine.js';
	import {
		fnFormatData,
		fnRecordGDStatusCode,
		uaFun
	} from "@/utils/public.js";
	import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	export default {
		name: "RecordGD",
		components: {
			HDialogC
		},
		setup() {
			const isApp = uaFun()
			const route = useRoute();
			const router = useRouter();
			const route_query = route.query;
			//离开当前的组件，触发
			onBeforeRouteLeave((to) => {
				if (to.path !== "/redirect" && route_query.redirect && to.path !== ("/" + route_query.redirect)) {
					router.replace({
						name: 'Redirect',
						params: {
							path: route_query.redirect
						}
					});
					return false;
				}
			});
			
			
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			
			// 当前页面数据
			const oCPData = reactive({
				oYCData: {
					dialogD: {
						showDialog: false,
						showCancelButton: false,
						confirmButtonText: "确定"
					}
				}
			})
			
			// 方案状态
			const oPlanStatus = reactive({
				show: false,
				select: {
					id: "",
					name: "全部"
				},
				option: [{
					id: "",
					name: "全部"
				}, {
					id: 1,
					name: "待开奖"
				}, {
					id: 2,
					name: "未中奖"
				}, {
					id: 3,
					name: "兑奖中"
				}, {
					id: 4,
					name: "已兑奖"
				}]
			});
			// 打开方案状态
			const fOpenPlanStatus = () => {
				console.log("方案状态");
				oPlanStatus.show = true;
				fCloseSearch();
			};
			// 关闭方案状态
			const fClosePlanStatus = () => {
				console.log("方案状态");
				oPlanStatus.show = false;
			};
			// 选择方案状态
			const fSelectPlanStatus = (Item) => {
				if (Item.name !== oPlanStatus.select.name) {
					oPlanStatus.select = Item;
					fOnLoadReset();
				}
				oPlanStatus.show = false;
			};


			// 佣金状态
			const oCommissionStatus = reactive({
				show: false,
				select: {
					id: "",
					name: "全部"
				},
				option: [{
					id: "",
					name: "全部"
				}, {
					id: false,
					name: "未支付"
				}, {
					id: true,
					name: "已支付"
				}]
			});
			// 打开佣金状态
			const fOpenCommissionStatus = () => {
				console.log("佣金状态");
				oCommissionStatus.show = true;
				fCloseSearch();
			};
			// 关闭方案状态
			const fCloseCommissionStatus = () => {
				console.log("佣金状态");
				oCommissionStatus.show = false;
			};
			// 选择佣金状态
			const fSelectCommissionStatus = (Item) => {
				if (Item.name !== oCommissionStatus.select.name) {
					oCommissionStatus.select = Item;
					fOnLoadReset();
				}
				console.log(Item)
				oCommissionStatus.show = false;
			};

			// 佣金状态
			const oSearch = reactive({
				show: false,
				value: "",
				inputVal: ""
			});
			// 打开搜索
			const fOpenSearch = () => {
				oSearch.show = !oSearch.show;
				if (oSearch.show) {
					oSearch.inputVal = oSearch.value
				}
			};
			// 关闭搜索
			const fCloseSearch = () => {
				oSearch.show = false;
			};
			// 开始搜索
			const fSearch = () => {
				oSearch.show = false;
				if (oSearch.value !== oSearch.inputVal) {
					oSearch.value = oSearch.inputVal;
					fOnLoadReset();
				}
			};


			// 方案--全部
			const oRecord = reactive({
				current: 0,
				size: 10,
				list: [],
				error: false,
				loading: false,
				finished: false
			});
			// 加载完成--方案--待支付
			const fOnLoad = () => {
				const current = oRecord.current + 1;
				apiGetPlanDocumentaryRecordOnePage({
					roleId: UserInfo.roleId,
					planStatus: oPlanStatus.select.id,
					commissionPayStatus: oCommissionStatus.select.id,
					key: oSearch.value,
					current,
					size: oRecord.size
				}).then(res => {
					oRecord.current = current;
					let tData = res && res.result ? res.result : {};
					let tRecords = tData.records ? tData.records : [];
					if (tRecords.length > 0) {
						oRecord.list.push(...tRecords);
						if (tData.hasNext) {
							oRecord.finished = false;
						} else {
							oRecord.finished = true;
						}
					} else {
						oRecord.finished = true;
					}
					oRecord.loading = false;
				}).catch(() => {
					oRecord.loading = false;
					oRecord.error = true;
				})
			};
			// 搜索重置
			const fOnLoadReset = () => {
				oRecord.current = 0;
				oRecord.size = 10;
				oRecord.list = [];
				oRecord.error = false;
				oRecord.loading = false;
				oRecord.finished = false;
				fOnLoad();
			};

			// 跟单的统计信息
			let oDetail = reactive({
				unpayCount: 0,
				unpayFee: 0
			})
			// 查询跟单的统计信息
			const fGetDetail = () => {
				apiGetPlanDocumentaryRecordDetail({
					id: UserInfo.roleId
				}).then(res => {
					let tData = res.result ? res.result : {};
					oDetail.unpayCount = tData.unpayCount;
					oDetail.unpayFee = tData.unpayFee;
				})
			};
			// 打开--立即支付窗口
			const fOpenPay = () => {
				if(oDetail.unpayFee <= 0){
					return
				}
				// 获取弹框信息
				apiImmediatelyPay({
					roleId: UserInfo.roleId,
					userId: UserInfo.userId,
					unPayCommission: oDetail.unpayFee
				}).then(() => {
					fOnLoadReset();
					fGetDetail();
					oCPData.oYCData.dialogD.showDialog = true;
				})
			};
			// 弹框关闭之前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				if (dialogKey === "dialogD") {
					if (action === "confirm") {
						oCPData.oYCData.dialogD.showDialog = false;
						fn(false)
					} else if (action === "cancel") {
						oCPData.oYCData.dialogD.showDialog = false;
						fn(false)
					}
				}
			};
			
			
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			
			fOnLoad();
			fGetDetail();
			return {
				oPlanStatus,
				fOpenPlanStatus,
				fClosePlanStatus,
				fSelectPlanStatus,

				oCommissionStatus,
				fOpenCommissionStatus,
				fCloseCommissionStatus,
				fSelectCommissionStatus,

				oSearch,
				fOpenSearch,
				fCloseSearch,
				fSearch,

				oRecord,
				fOnLoad,
				
				oDetail,
				fOpenPay,
				
				
				oCPData,
				fBeforeClose,
				
				fnFormatData,
				fnRecordGDStatusCode,
				fLeft,
				isApp
			}
		}
	}
</script>

<style scoped lang="scss">
	.recordGD {
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		box-sizing: border-box;

		.recordGD_body {
			padding: 0 0.32rem;
		}
	}

	.recordGD_zIndex {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 5;
		background: #F1F2F7;
		border-radius: 0px 0px 8px 8px;
	}

	.recordGD_HDropdownMenu {
		padding: 0.1066rem 0.32rem;
		display: flex;
		align-items: center;
	}

	.HDropdownMenu {
		position: relative;

		.HDropdownMenu_show {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.2133rem 0.5066rem;

			min-width: 2.5866rem;
			box-sizing: border-box;

			&>span {
				font-size: 0.32rem;
				font-weight: 500;
				color: #383838;
				line-height: 0.4533rem;
			}

			&>img {
				margin-left: 0.1066rem;
				width: 0.1866rem;
				height: auto;
			}
		}

		.HDropdownMenu_show--select {
			&>span {
				color: #FF7671;
			}

			&>img {
				margin-left: 0.1066rem;
				width: 0.1866rem;
				height: auto;
			}
		}

		.HDropdownMenu_content {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 30;

			background: #FFFFFF;
			box-shadow: 0px 0.0533rem 0.2666rem 0px rgba(224, 228, 246, 0.8);
			border-radius: 0.2133rem;

			.HDropdownMenu_content_title {
				display: flex;
				align-items: center;
				padding: 0.2133rem 0.5066rem;

				&>span {
					font-size: 0.32rem;
					font-weight: 500;
					color: #FF7671;
					line-height: 0.4533rem;
				}

				&>img {
					margin-left: 0.1066rem;
					width: 0.1866rem;
					height: auto;
				}
			}

			.HDropdownMenu_content_option {
				margin: 0.2666rem 0.48rem;
				padding: 0.0266rem 0;
				text-align: center;

				font-size: 0.32rem;
				font-weight: 500;
				color: #383838;
				line-height: 0.4533rem;
			}

			.HDropdownMenu_content_option--selected {
				color: #FF7671;
			}
		}

		.HDropdownMenu_mask {
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 10;
		}
	}

	.HDropdownMenu_search {
		flex: 1;

		.HDropdownMenu_show {
			justify-content: flex-end;
		}
	}

	.HDropdownMenu_search_mask {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 4;
		background: #000000;
		opacity: 0.72;
	}

	.recordGD_body {
		padding: 0 0.32rem;

		.recordGD_body_card {
			background: #FFFFFF;
			box-shadow: 0px 0.0533rem 0.2133rem 0px rgba(224, 228, 246, 0.5);
			border-radius: 0.2133rem;
			padding: 0.4rem 0.4rem 0;
			margin-bottom: 0.32rem;

			.recordGD_body_card_a {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 0.4533rem;

				.recordGD_body_card_a_a {
					display: flex;
					align-items: center;

					.recordGD_body_card_a_a_a--red {
						width: 0.1066rem;
						height: 0.48rem;
						background: #F21B8E;
						border-radius: 0.0533rem;
					}

					.recordGD_body_card_a_a_a--blue {
						width: 0.1066rem;
						height: 0.48rem;
						background: #033EC2;
						border-radius: 0.0533rem;
					}

					.recordGD_body_card_a_a_b {
						margin-left: 0.1066rem;
						font-size: 0.3733rem;
						font-weight: 600;
						color: #383838;
						line-height: 0.5333rem;
					}

					.recordGD_body_card_a_a_c {
						margin-left: 0.1066rem;
						font-size: 0.3733rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.5333rem;
					}
				}

				.recordGD_body_card_a_b {
					font-size: 0.32rem;
					font-weight: 400;
					color: #383838;
					line-height: 0.4533rem;

					&>em {
						font-weight: 500;
					}
				}
			}

			.recordGD_body_card_b {
				padding-left: 0.2133rem;
				margin-bottom: 0.4rem;
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				.recordGD_body_card_b_a {
					.recordGD_body_card_b_a_a {
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.4533rem;
						margin-bottom: 0.16rem;

						&>em {
							font-size: 0.4266rem;
							font-weight: bold;
							color: #383838;
							line-height: 0.5066rem;
						}
					}

					.recordGD_body_card_b_a_b {
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.4533rem;

						&>em {
							font-size: 0.32rem;
							font-weight: 400;
							color: #383838;
							line-height: 0.4533rem;
						}
					}
				}

				.recordGD_body_card_b_b {
					font-size: 0.32rem;
					font-weight: 400;
					color: #383838;
					line-height: 0.4533rem;

					&>em {
						font-size: 0.4266rem;
						font-weight: bold;
						color: #E2335D;
						line-height: 0.5066rem;
					}
				}
			}

			.recordGD_body_card_c {
				border-top: 0.0266rem solid #EAEAEA;
				padding: 0.2133rem 0;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.recordGD_body_card_c_a {
					font-size: 0.32rem;
					font-weight: 400;
					color: #979797;
					line-height: 0.4533rem;
				}
			}
		}
	}

	.recordGD_bottom {
		padding: 0.4266rem;
		background: #FFFFFF;
		border-radius: 0.2133rem 0.2133rem 0px 0px;

		display: flex;
		align-items: center;
		justify-content: space-between;

		.recordGD_bottom_a {
			.recordGD_bottom_a_a {
				font-size: 0.3733rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.5333rem;

				&>em {
					font-size: 0.48rem;
					font-weight: bold;
					color: #383838;
					line-height: 0.56rem;
				}
			}

			.recordGD_bottom_a_b {
				font-size: 0.3733rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.5333rem;

				&>em {
					font-size: 0.48rem;
					font-weight: bold;
					color: #E2335D;
					line-height: 0.56rem;
				}
			}
		}
	}

	.recordGD_tips {
		text-align: center;
		padding: 0.2666rem 0.8533rem;
		font-size: 0.4266rem;
		font-weight: 400;
		color: #383838;
		line-height: 0.5866rem;
	}
</style>
