<template>
	<div class="mineInfo" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box;' : ''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="个人信息" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="app_scroll pre_box">
			<van-uploader :before-read="fBeforeRead">
				<div class="pre_box_item">
					<div class="left">头像设置</div>
					<div class="right">
						<img :src="oMyInfo.head ? oMyInfo.head : defaultAvatar" alt="" class="right_img1">
						<img src="~@/assets/icon/icon-007.png" alt="" class="right_img2">
					</div>
				</div>
			</van-uploader>
			<div class="pre_box_item">
				<div class="left">角色名称</div>
				<div class="right">
					{{oMyInfo.roleName}}
				</div>
			</div>
			<div class="pre_box_item">
				<div class="left">手机号码</div>
				<div class="right">
					{{oMyInfo.accountNo}}
				</div>
			</div>
			<div class="pre_box_item">
				<div class="left">身份信息</div>
				<div class="right">
					{{oMyInfo.identityInfo}}
				</div>
			</div>
		</div>
		<div class="bot">
			<van-button class="app_button addRole_bot" round block native-type="submit" @click="fLeft">
				确认
			</van-button>
		</div>
	</div>
</template>

<script>
	import {
		computed
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		Toast
	} from 'vant';
	import axios from 'axios';
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnCompressorImage,
		uaFun
	} from "@/utils/public.js";

	import {
		apiUploadFile
	} from "@/api/other.js";
	import defaultAvatar from "@/assets/images/img_032.png";
	export default {
		name: "MineInfo",
		setup() {
			const isApp = uaFun()
			const store = useStore(); // 使用useStore方法
			// 我的信息
			const oMyInfo = computed(() => store.state.userInfo.myInfo);

			// 上传前校验
			const fBeforeRead = (file, name) => {
				console.log(file, name)
				if (!/\/(?:jpeg|jpg|png)/i.test(file.type)) {
					Toast('请上传 jpeg或jpg或png 格式图片');
					return false;
				}

				// 获取oss上传参数
				let oOssParams = null;

				apiUploadFile({
					fileName: file.name,
					roleId: oMyInfo.value.roleId
				}).then(res => {
					console.log(res);
					oOssParams = res && res.result ? res.result : {};
					// 压缩图片
					return fnCompressorImage(file, 0.3, "file");
				}).then((fileNew) => {
					// 获取到压缩后的文件
					fUploadFileOSS(oOssParams, fileNew);
				}).catch(() => {
					Toast({
						message: "上传失败",
						position: 'bottom'
					});
				})
			};


			// OSS上传（后台返回数据，新文件）
			const fUploadFileOSS = (OssParams, File) => {
				// let that = this;
				console.log(OssParams);
				//创建一个空对象实例
				let formData = new FormData();
				//获取当前时间戳加上获取的文件后缀 为文件名
				// let filename = new Date().getTime();
				//注意formData里append添加的键的大小写
				formData.append('key', OssParams.savePath + File.name); //存储在oss的文件路径
				// formData.append('key', file.name); //存储在oss的文件路径
				formData.append('OSSAccessKeyId', OssParams.accessId); //accessKeyId
				formData.append('policy', OssParams.policy); //policy
				formData.append('Signature', OssParams.signature); //签名
				formData.append('callback', OssParams.callback); //通知服务器
				formData.append('success_action_status', 200); //成功后返回的操作码
				// 将文件存入file下面，这个一定要放到最后！！！不然会出错
				formData.append("file", File);
				// 调用接口
				axios.post(OssParams.host, formData).then(() => {
					// 转base64
					let reader = new FileReader();
					reader.readAsDataURL(File);
					reader.onload = function(e) {
						oMyInfo.value.head = e.target.result;
					};
				}).catch(() => {
					Toast({
						message: "上传失败",
						position: 'bottom'
					});
				})
			};

			/**
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			return {
				oMyInfo,
				fBeforeRead,
				fLeft,
				isApp,
				defaultAvatar
			}
		}
	}
</script>

<style scoped lang="scss">
	.pre_box {
		padding-top: 0.32rem;
		box-sizing: border-box;

		.pre_box_item {
			width: calc(100% - 1.44rem);
			margin-left: 0.72rem;
			height: 1.493rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 0.027rem solid #F5F5F5;

			.left {
				font-size: 0.373rem;
				font-weight: 400;
				color: #383838;
			}

			.right {
				display: flex;
				align-items: center;
				font-size: 0.32rem;
				font-weight: 400;
				color: #979797;

				.right_img1 {
					width: 1.013rem;
					height: 1.013rem;
					border-radius: 50%;
					margin-right: 0.213rem;
				}

				.right_img2 {
					width: 0.16rem;
					height: 0.267rem;
				}
			}
		}
	}

	.bot {
		width: 100%;
		height: 2.507rem;
		background: #FFFFFF;
		border-radius: 0.213rem 0.213rem 0px 0px;
		position: fixed;
		left: 0;
		bottom: 0;
		padding-top: 0.427rem;
		box-sizing: border-box;

		.addRole_bot {
			width: calc(100% - 0.853rem);
			margin-left: 0.427rem;
		}
	}
</style>
