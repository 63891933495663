<template>
	<div class="listGDR app_flex" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box;' : ''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar class="listGDR_bar" title="跟单列表" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="listGDR_tips" v-if="oDetail.reason">
			{{oDetail.reason}}
		</div>
		<div class="listGDR_body app_flex">
			<div class="listGDR_body_total">
				<div>
					<p>￥{{oDetail.totalCommission}}</p>
					<p>总佣金</p>
				</div>
				<div>
					<p>{{oDetail.totalNumber}}</p>
					<p>总跟单人数</p>
				</div>
				<div>
					<p>￥{{oDetail.receivedCommission}}</p>
					<p>已收佣金</p>
				</div>
				<div>
					<p>￥{{oDetail.unpayCommission}}</p>
					<p>待收佣金</p>
				</div>
			</div>

			<div v-if="oRecord.list.length > 0" class="app_scroll">
				<van-list v-model:loading="oRecord.loading" :finished="oRecord.finished" v-model:error="oRecord.error"
					error-text="请求失败，点击重新加载" finished-text="没有更多了" @load="fOnLoad">
					<div class="listGDR_list" v-for="(item, index) in oRecord.list" :key="index">
						<div class="listGDR_list_a">
							<div class="listGDR_list_a_a">
								<span class="listGDR_list_a_a_a">跟单人：</span>
								<span class="listGDR_list_a_a_b">{{item.roleName}}</span>
							</div>
							<div class="listGDR_list_a_b">
								<span class="listGDR_list_a_b_a">佣金状态：</span>
								<span class="listGDR_list_a_b_b">{{item.commissionPayStatusMsg}}</span>
							</div>
						</div>

						<div class="listGDR_list_b">
							<div class="listGDR_list_b_a">
								<div>
									<span>投注金额：</span>
									<span>¥{{item.totalFee}}</span>
								</div>
								<div>
									<span>中奖金额：</span>
									<span>¥{{item.afterTaxPrize ? item.afterTaxPrize:"0.00"}}</span>
								</div>
							</div>
							<div class="listGDR_list_b_b">
								<span class="listGDR_list_b_b_a">获得佣金：</span>
								<span
									class="listGDR_list_b_b_b">¥{{item.isCommission ? item.commissionAmount:"0.00"}}</span>
							</div>
						</div>

						<div class="listGDR_list_c">
							方案编号：{{item.id}}
						</div>
					</div>
				</van-list>
			</div>
			<div v-else class="app_scroll">
				<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')"
					description="目前没有跟单列表…" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		reactive
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRoute
	} from "vue-router";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		apiGetPlanBillDocumentaryDetail,
		apiGetPlanBillDocumentaryOnePage
	} from '@/api/mine.js';
	import {
		uaFun
	} from "@/utils/public.js";
	export default {
		name: "ListGDR",
		setup() {
			const isApp = uaFun()
			const route = useRoute();
			const route_query = route.query;
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			// 详情
			const oDetail = reactive({
				reason: null,
				totalCommission: 0.00,
				totalNumber: 0,
				receivedCommission: 0.00,
				unpayCommission: 0.00
			})
			// 获取详情
			const fGetDetail = () => {
				apiGetPlanBillDocumentaryDetail({
					id: route_query.id
				}).then(res => {
					let tData = res.result ? res.result : {};
					oDetail.reason = tData.reason;
					oDetail.totalCommission = tData.totalCommission;
					oDetail.totalNumber = tData.totalNumber;
					oDetail.receivedCommission = tData.receivedCommission;
					oDetail.unpayCommission = tData.unpayCommission;
				})
			};

			const oRecord = reactive({
				current: 0,
				size: 10,
				list: [],
				error: false,
				loading: false,
				finished: false
			});
			const fOnLoad = () => {
				const current = oRecord.current + 1;
				apiGetPlanBillDocumentaryOnePage({
					roleId: UserInfo.roleId,
					planId: route_query.id,
					current,
					size: oRecord.size
				}).then(res => {
					oRecord.current = current;
					let tData = res && res.result ? res.result : {};
					let tRecords = tData.records ? tData.records : [];
					if (tRecords.length > 0) {
						oRecord.list.push(...tRecords);
						if (tData.hasNext) {
							oRecord.finished = false;
						} else {
							oRecord.finished = true;
						}
					} else {
						oRecord.finished = true;
					}
					oRecord.loading = false;
				}).catch(() => {
					oRecord.loading = false;
					oRecord.error = true;
				})
			};

			const fTransitionState = (val) => {
				switch (val) {
					case true:
						return '已支付';
					case false:
						return '未支付';
					default:
						return ''
				}
			};
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			fOnLoad();
			fGetDetail();
			return {
				oDetail,

				oRecord,
				fOnLoad,

				fTransitionState,
				isApp,
				fLeft
			}
		}
	}
</script>

<style scoped lang="scss">
	.listGDR {
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		box-sizing: border-box;

		.listGDR_tips {
			margin: 0.32rem auto;
			padding: 0.1333rem 0.32rem;
			text-align: center;
			background: rgba(255, 118, 113, 0.15);
			border-radius: 0.3466rem;
			border: 0.0266rem solid #FF7671;

			font-size: 0.32rem;
			font-weight: 400;
			color: #E2335D;

			display: flex;
		}

		.listGDR_body {
			flex: 1;
			overflow: hidden;
			box-sizing: border-box;
			background: #FAFBFF;
			box-shadow: 0px 0.0533rem 0.4266rem 0px rgba(224, 228, 246, 0.5);
			border-radius: 0.32rem 0.32rem 0px 0px;

			.listGDR_body_total {
				margin: 0.2133rem 0.1866rem 0.4266rem;
				display: flex;
				align-items: center;

				&>div {
					flex: 1;
					padding: 0.2666rem;
					text-align: center;
					position: relative;

					&>p:first-child {
						font-size: 0.4rem;
						font-weight: bold;
						color: #E2335D;
					}

					&>p:last-child {
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.4533rem;
						margin-top: 0.1066rem;
					}
				}

				&>div:not(:last-child):after {
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					content: "";
					width: 0.0266rem;
					height: 0.56rem;
					background: #EAEAEA;
				}
			}

			.listGDR_list {
				margin: 0 0.32rem 0.32rem;
				padding: 0.4rem 0.4rem 0;
				background: #FFFFFF;
				box-shadow: 0px 0.0533rem 0.2133rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.2133rem;

				.listGDR_list_a {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 0.4266rem;

					.listGDR_list_a_a {
						display: flex;
						align-items: center;

						.listGDR_list_a_a_a {
							font-size: 0.3733rem;
							font-weight: 600;
							color: #383838;
						}

						.listGDR_list_a_a_b {
							font-size: 0.3733rem;
							font-weight: 400;
							color: #383838;
						}
					}

					.listGDR_list_a_b {
						.listGDR_list_a_b_a {
							font-size: 0.32rem;
							font-weight: 400;
							color: #383838;
						}

						.listGDR_list_a_b_b {
							font-size: 0.32rem;
							font-weight: 500;
							color: #E2335D;
						}
					}
				}

				.listGDR_list_b {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					padding-bottom: 0.3466rem;
					border-bottom: 0.0266rem solid #EAEAEA;

					.listGDR_list_b_a {
						&>div {
							display: flex;
							align-items: center;

							&>span:first-child {
								font-size: 0.32rem;
								font-weight: 400;
								color: #383838;
							}

							&>span:last-child {
								margin-top: 0.0533rem;
								font-size: 0.4266rem;
								font-weight: bold;
								color: #383838;
							}
						}
					}

					.listGDR_list_b_b {
						.listGDR_list_b_b_a {
							font-size: 0.32rem;
							font-weight: 400;
							color: #383838;
						}

						.listGDR_list_b_b_b {
							font-size: 0.4266rem;
							font-weight: bold;
							color: #383838;
						}
					}
				}

				.listGDR_list_c {
					padding: 0.2133rem 0;
					text-align: right;

					font-size: 0.32rem;
					font-weight: 400;
					color: #979797;
				}
			}
		}
	}
</style>
