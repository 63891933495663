<template>
	<van-dialog class="HDialogA" v-model:show="propsData.show"
		:show-cancel-button="propsData.showCancelButton" :showConfirmButton="propsData.showConfirmButton" :confirmButtonText="propsData.confirmButtonText" @cancel="cancel" @confirm="confirm" :beforeClose="beforeClose" theme="round-button">
		<template #title>
			<div class="HDialogA_title">
				{{title}}
				<img class="HDialogA_title_img" src="~@/assets/icon/icon-028.png" @click="beforeClose('cancel')">
			</div>
		</template>
		<slot></slot>
	</van-dialog>
</template>

<script>
	import {
		ref,
		reactive,
		watch
	} from 'vue'
	export default {
		name: "HDialogA",
		props: {
			title: {
				type: String,
				default: " "
			},
			// dialog唯一值
			dialogKey: {
				type: String,
				required: true
			},
			// 是否显示
			show: {
				type: Boolean,
				default: false
			},
			// 是否展示取消按钮
			showCancelButton: {
				type: Boolean,
				default: false
			},
			// 是否展示确认按钮
			showConfirmButton: {
				type: Boolean,
				default: true
			},
			// 确定按钮文本
			confirmButtonText: {
				type: String,
				default: "确定"
			}
		},
		setup(props, context) {
			const propsData = reactive({
				title: props.title,
				show: props.show,
				showCancelButton: props.showCancelButton,
				confirmButtonText: props.confirmButtonText,
				showConfirmButton: props.showConfirmButton,
			});
			const propsTitle = ref(props.title);
			const cancel = () => {};
			const confirm = () => {};
			const beforeClose = (action) => {
				const {
					dialogKey
				} = props;
				// console.log(dialogKey);
				context.emit("fBeforeClose", action, {
					dialogKey
				}, (tf) => {
					propsData.show = tf;
				})
			};
			// watch只能同时监听两个数值
			watch(
				[() => props.show],
				([newShow]) => {
					// console.log(newShow);
					propsData.show = newShow;
				}
			);
			watch(
				[() => props.title, () => props.showCancelButton],
				([newTitle]) => {
					propsData.title = newTitle;
				},
				([newShowCancelButton]) => {
					propsData.showCancelButton = newShowCancelButton;
				}
			);
			return {
				propsTitle,
				propsData,
				cancel,
				confirm,
				beforeClose
			}
		}
	}
</script>

<style scoped lang="scss">
	.HDialogA_title{
		min-height: 0.64rem;
		position: relative;
		font-size: 0.4266rem;
		font-weight: 600;
		color: #383838;
		.HDialogA_title_img{
			width: 0.64rem;
			height: 0.64rem;
			position: absolute;
			right: 0.32rem;
			top: 0;
			bottom: 0;
			margin: auto 0;
		}
	}
	
</style>
