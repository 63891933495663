<template>
	<div class="listBANK app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="银行名称" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		
		<div class="listBANK_body app_scroll mt12">
			<div class="listBANK_list" v-for="(item, index) in aBanks" :key="index" @click="fSelectBank(item)">
				<img :src="item.bankPic" alt="">
				<span>{{item.bankName}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref } from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		onBeforeRouteLeave
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		apiAccountFindBanks
	} from '@/api/mine.js';
	export default {
		name: "ListBANK",
		setup() {
			//离开当前的组件，触发
			onBeforeRouteLeave((to) => {
				if (to.path === "/withdraw") {
					to.meta.resetType = "2";
				}
			});
			const store = useStore(); // 使用useStore方法
			// 银行列表
			const aBanks = ref([]);
			// 选中的银行
			const oSelectBank = ref({});
			
			// 获取银行列表
			const fGetBanks = () => {
				Toast({
					type: "loading"
				});
				apiAccountFindBanks({}).then(res => {
					aBanks.value = res.result ? res.result : [];
				})
			};
			fGetBanks();
			// 选中数据
			const fSelectBank = (item) => {
				oSelectBank.value = item.bankName;
				store.dispatch('other/UWithdrawInfo',{'bankName': oSelectBank.value});
				fnIsHasHistory("/mine");
			};
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			
			
			return {
				aBanks,
				oSelectBank,
				
				fSelectBank,
				fLeft
			}
		}
	}
</script>

<style scoped lang="scss">
	.listBANK {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		.listBANK_body {
			padding: 0 0.72rem 0.2666rem;
			.listBANK_list {
				padding: 0.2666rem 0;
				border-bottom: 0.0266rem solid #F5F5F5;
				
				display: flex;
				align-items: center;
				&>img {
					width: 0.96rem;
					height: 0.96rem;
					margin-right: 0.4266rem;
				}
				&>span {
					font-size: 0.3733rem;
					font-weight: 400;
					color: #383838;
				}
			}
		}
	}
</style>
