<template>
	<div class="notices_box app_flex" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box;' : ''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="消息" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="box app_scroll">
			<van-list v-if="aNotices.list.length > 0" v-model:loading="aNotices.loading" v-model:error="aNotices.error" error-text="请求失败，点击重新加载"
				:finished="aNotices.finished" finished-text="没有更多了" @load="fOnLoad">
				<div class="box_item" v-for="(item,index) in aNotices.list" :key="index" @click="fGoDetail(item)">
					<div class="item_title" :class="{'item_title_noRead' : !item.isRead}">
						{{item.noticeTitle}}
					</div>
					<div class="item_conter">
						{{item.noticeContent}}
					</div>
					<div class="item_time">
						{{fnFormatData(item.sendTime)}}
					</div>
				</div>
			</van-list>
			<div v-else class="app_scroll">
				<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')"
					description="目前没有消息…" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		reactive
	} from "vue";
	import {
		useStore
	} from "vuex";
	import {
		useRouter
	} from "vue-router";
	import {
		apiGetNoticeOnePage
	} from "@/api/mine.js";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnFormatData,
		uaFun
	} from "@/utils/public.js";
	export default {
		name: "Notices",
		setup() {
			const isApp = uaFun()
			const router = useRouter(); //初始化路由
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			// 方案--全部
			const aNotices = reactive({
				current: 0,
				size: 10,
				list: [],
				error: false,
				loading: false,
				finished: false
			});
			// 加载完成--方案--待支付
			const fOnLoad = () => {
				const current = aNotices.current + 1;

				apiGetNoticeOnePage({
					roleId: UserInfo.roleId,
					current,
					size: aNotices.size
				}).then(res => {
					aNotices.current = current;
					let tData = res && res.result ? res.result : {};
					let tRecords = tData.records ? tData.records : [];
					if (tRecords.length > 0) {
						aNotices.list.push(...tRecords);
						if (tData.hasNext) {
							aNotices.finished = false;
						} else {
							aNotices.finished = true;
						}
					} else {
						aNotices.finished = true;
					}
					aNotices.loading = false;
				}).catch(() => {
					aNotices.loading = false;
					aNotices.error = true;
				})
			};

			// 前往地址
			const fGoDetail = (noticeDetail) => {
				store.dispatch('other/UNoticeDetail', noticeDetail);
				router.push({
					path: '/noticeDetail',
					query: {
						id: noticeDetail.id
					}
				});
			};
			
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			
			fOnLoad();
			return {
				aNotices,

				fGoDetail,
				fnFormatData,
				fOnLoad,
				
				fLeft,
				isApp
			}
		}
	}
</script>

<style scoped lang="scss">
	.notices_box {
		height: 100%;
		background: #F1F2F7;

		.box {
			padding: 0.32rem;
			box-sizing: border-box;

			.box_item {
				margin-bottom: 0.32rem;
				width: 100%;
				padding: 0.4rem 0.4rem 0;
				box-sizing: border-box;
				background: #FFFFFF;
				box-shadow: 0px 0.053rem 0.213rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.213rem;

				.item_title {
					line-height: 0.533rem;
					font-size: 0.373rem;
					font-weight: 600;
					color: #383838;
					margin-bottom: 0.4rem;

					position: relative;
				}

				.item_title_noRead::after {
					position: absolute;
					right: 0;
					top: 50%;
					margin-top: -0.0933rem;

					content: "";
					display: inline-block;
					width: 0.1866rem;
					height: 0.1866rem;
					border-radius: 50%;
					background: #FF7671;
				}

				.item_conter {
					font-size: 0.32rem;
					font-weight: 400;
					color: #979797;
					line-height: 0.453rem;
					margin-bottom: 0.4rem;


					overflow: hidden;
					word-break: break-all;
					display: -webkit-box;
					text-overflow: ellipsis;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}


				.item_time {
					height: 0.88rem;
					text-align: right;
					border-top: 0.027rem solid #EAEAEA;
					line-height: 0.88rem;
					font-size: 0.32rem;
					font-weight: 400;
					color: #979797;
				}
			}
		}
	}
</style>
