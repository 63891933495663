<template>
	<div class="withdraw app_flex"  :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box;' : ''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar class="listGDR_bar" title="提现" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>

		<div class="withdraw_tips">
			*为保证资金安全，提现账户必须与实名认证一致
		</div>

		<div class="withdraw_body app_scroll">
			<div class="withdraw_body_a">
				<p class="withdraw_body_a_title">提现方式：</p>
				<van-radio-group v-model="oCPData.oYCData.checked" checked-color="#FF7671">
					<van-cell-group>
						<van-cell clickable @click="oCPData.oYCData.checked = '1'">
							<template #title>
								<div class="withdraw_body_a_a">
									<img src="~@/assets/images/img_022.png">
									<span>支付宝</span>
								</div>
							</template>
							<template #right-icon>
								<van-radio name="1" />
							</template>
						</van-cell>
						<van-cell clickable @click="oCPData.oYCData.checked = '2'">
							<template #title>
								<div class="withdraw_body_a_a">
									<img src="~@/assets/images/img_023.png">
									<span>银行卡</span>
								</div>
							</template>
							<template #right-icon>
								<van-radio name="2" />
							</template>
						</van-cell>
					</van-cell-group>
				</van-radio-group>
			</div>

			<div class="withdraw_body_b">
				<span class="withdraw_body_title">个人信息</span>
				<span class="withdraw_body_b_a">{{oMyInfo.identityInfo}}</span>
			</div>


			<template v-if="oCPData.oYCData.checked === '1'">
				<van-field class="withdraw_field" v-model="oZFB.account" label="支付宝账户" placeholder="请输入账号" input-align="right" :border="false" />
				<van-field class="withdraw_field" v-model="oZFB.realName" label="真实姓名" placeholder="请输入真实姓名" input-align="right" :border="false" />
			</template>
			<template v-else-if="oCPData.oYCData.checked === '2'">

				<van-field class="withdraw_field" v-model="oYH.bankName" label="银行名称" placeholder="请选择银行"
					input-align="right" :border="false" readonly @click="fGoPath('listBANK')">
					<template #right-icon>
						<img class="withdraw_field_rIcon" src="~@/assets/icon/icon-007.png">
					</template>
				</van-field>
				<van-field class="withdraw_field" v-model="oYH.account" type="digit" label="银行卡号" placeholder="请输入银行卡号" input-align="right" :border="false" />
				<van-field class="withdraw_field" v-model="oYH.openingBank" label="开户行" placeholder="请输入开户行" input-align="right" :border="false" />
				<van-field class="withdraw_field" v-model="oYH.openingPersonName" label="开户人姓名" placeholder="请输入开户人姓名" input-align="right" :border="false" />
			</template>

			<div class="withdraw_body_c mt12">
				<div class="withdraw_body_c_a">
					<div class="withdraw_body_c_a_a">
						<img src="~@/assets/icon/icon-103.png" @click="fOpenDialog('oDialogA')">
						<span>提现金额：</span>
					</div>
					<div class="withdraw_body_c_a_b">
						<span>可提金额 <em>{{oCPData.oNCData.canWithdrawalAmount}}元</em></span>
					</div>
				</div>

				<div class="withdraw_body_c_b">
					<span class="withdraw_body_c_b_a">￥</span>
					<van-field class="withdraw_body_c_b_b" v-model="sMoney" placeholder="请输入提现金额" clearable
						@update:model-value="fUpdateMoney" @blur="fBlurMoney" />
					<span class="withdraw_body_c_b_c" @click="fAllWithdraw">全部提现</span>
				</div>

				<div class="withdraw_body_c_c">
					实际到账金额 {{oCPData.oYCData.actualAccount ? oCPData.oYCData.actualAccount : 0}}元
				</div>
			</div>

			<div class="withdraw_body_d"
				v-html="(fnFormatColor(oCPData.oNCData.minimumText) + (oCPData.oNCData.minimumText && oCPData.oNCData.singleMaxWithdrawalAmountText ? '，' : '') + fnFormatColor(oCPData.oNCData.singleMaxWithdrawalAmountText))">
			</div>
			<!-- <div>
				<p>最低提现金额 <em class="cE2335D">{{oCPData.oNCData.minimum}}元</em>，最高充值金额 <em
						class="cE2335D">{{oCPData.oNCData.singleMaxWithdrawalAmount}}元</em></p>
				<p>提现将会在36小时内到账，若超时未到账请联系客服</p>
			</div> -->
		</div>
		<div class="withdraw_bottom">
			<van-button round block native-type="submit" class="app_button" :disabled="oCPData.oYCData.disabled"
				@click="fSubmit">
				提交
			</van-button>
		</div>

		<h-dialog-a title="提示" dialogKey="oDialogA" :show="oDialogA.showDialog"
			:showCancelButton="oDialogA.showCancelButton" :showConfirmButton="oDialogA.showConfirmButton"
			:confirmButtonText="oDialogA.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="withdraw_tips_dialog">
				为防止非法洗钱，充值成功金额只有在平台消费规定的100%后，剩余部分才可提现。
			</div>
		</h-dialog-a>
		<h-dialog-c title="提示" dialogKey="oDialogB" :show="oDialogB.showDialog"
			:showCancelButton="oDialogB.showCancelButton" :confirmButtonText="oDialogB.confirmButtonText"
			@fBeforeClose="fBeforeClose">
			<div class="withdraw_tips_dialogB">
				您有跟单方案未支付佣金，请先支付完佣金后再投注。
			</div>
		</h-dialog-c>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		onActivated,
		inject,
		computed
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		Toast
	} from 'vant';
	import {
		useRoute,
		useRouter
	} from "vue-router";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnDeepClone,
		fnKeepFigures,
		fnFormatColor,
		uaFun
	} from "@/utils/public.js";
	import tipsContent from '@/utils/tipsContent';
	import {
		apiAccountPreWithdrawal,
		apiAccountCalculate,
		apiAccountWithdrawal
	} from '@/api/mine.js';
	import HDialogA from "@/components/HDialog/HDialogA/index.vue";
	import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	export default {
		name: "Withdraw",
		components: {
			HDialogA,
			HDialogC
		},
		setup() {
			const isApp = uaFun()
			const route = useRoute(); //初始化路由
			const router = useRouter(); //初始化路由
			const fGlobalReload = inject("fGlobalReload");
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			// 我的信息
			const oMyInfo = computed(() => store.state.userInfo.myInfo);
			// 支付宝
			const oZFB = reactive({
				account: null,
				realName: null,
				withdrawalTypeEnum: "ALIPAY"
			});
			// 银行
			const oYH = reactive({
				account: null,
				bankName: null,
				openingBank: null,
				openingPersonName: null,
				withdrawalTypeEnum: "BANK_CARD"
			});
			console.log(oYH);
			// 页面数据
			const oCPData = reactive({
				oNCData: {
					// 可提现金额
					canWithdrawalAmount: null,
					// 消费百分比
					consumePercent: null,
					// 最低提现金额
					minimum: null,
					// 最低提现金额提示语
					minimumText: null,
					// 单次最高提现金额
					singleMaxWithdrawalAmount: null,
					// 单次最高提现金额提示语
					singleMaxWithdrawalAmountText: null,
				},
				oYCData: {
					disabled: false,
					checked: "1",
					actualAccount: null
				}
			});
			// 提现金额
			const sMoney = ref("");

			// 页面数据
			const fGetCPData = () => {
				apiAccountPreWithdrawal({
					roleId: UserInfo.roleId
				}).then(res => {
					let tData = res.result ? res.result : {};
					oCPData.oNCData.canWithdrawalAmount = tData.canWithdrawalAmount;
					oCPData.oNCData.consumePercent = tData.consumePercent;
					oCPData.oNCData.minimum = tData.minimum;
					oCPData.oNCData.minimumText = tData.minimumText;
					oCPData.oNCData.singleMaxWithdrawalAmount = tData.singleMaxWithdrawalAmount;
					oCPData.oNCData.singleMaxWithdrawalAmountText = tData.singleMaxWithdrawalAmountText;
					if (tData.preUserResponse) {
						oCPData.oYCData.checked = tData.preUserResponse.withdrawalType + '';
						// 1:支付宝;2:银行卡
						if (oCPData.oYCData.checked == 1) {
							oZFB.account = tData.preUserResponse.account;
							oZFB.realName = tData.preUserResponse.realName;
						} else if (oCPData.oYCData.checked == 2) {
							oYH.account = tData.preUserResponse.account;
							oYH.bankName = tData.preUserResponse.bankName;
							oYH.openingBank = tData.preUserResponse.openingBank;
							oYH.openingPersonName = tData.preUserResponse.openingPersonName;
							store.dispatch('other/UWithdrawInfo', {
								bankName: oYH.bankName
							});
						}
					}
				})
			};
			// 全部提现
			const fAllWithdraw = () => {
				sMoney.value = oCPData.oNCData.canWithdrawalAmount;
				let tData = sMoney.value;
				let tDataA = oCPData.oNCData.singleMaxWithdrawalAmount;
				if (Number(tData) >= Number(tDataA)) {
					sMoney.value = tDataA
				}
				fGetActualAccount();
			};
			// 获取实际到账金额
			const fGetActualAccount = () => {
				Toast({
					type: "loading"
				});
				apiAccountCalculate({
					roleId: UserInfo.roleId,
					withdrawalAccount: sMoney.value
				}).then(res => {
					oCPData.oYCData.actualAccount = res.result && res.result.actualAccount ? res.result
						.actualAccount : '0.00';
				})
			};
			// 充值金额--更新
			const fUpdateMoney = (Value) => {
				console.log(sMoney.value)
				let tData = Value;
				let tDataA = oCPData.oNCData.singleMaxWithdrawalAmount;
				let tDataB = oCPData.oNCData.canWithdrawalAmount;
				console.log(tData)
				sMoney.value = tData;
				if (Number(sMoney.value) >= Number(tDataB)) {
					sMoney.value = "";
					sMoney.value = tDataB;
				}
				if (Number(sMoney) >= Number(tDataA)) {
					sMoney.value = "";
					sMoney.value = tDataA;
				}
			};
			// 充值金额--失焦
			const fBlurMoney = () => {
				console.log(sMoney.value)
				let tData = sMoney.value;
				let tDataA = oCPData.oNCData.singleMaxWithdrawalAmount;
				let tDataB = oCPData.oNCData.canWithdrawalAmount;
				// 保留两位小数
				tData = fnKeepFigures(tData, 2);
				// 清空输入框
				sMoney.value = "";
				// 再进行赋值，这个操作为了防止小数点保留
				sMoney.value = tData;
				if (Number(sMoney.value) >= Number(tDataB)) {
					sMoney.value = tDataB;
				}

				if (Number(sMoney.value) >= Number(tDataA)) {
					sMoney.value = tDataA;
				}

				// 提现金额是否有值
				if (sMoney.value > 0) {
					fGetActualAccount();
				} else {
					oCPData.oYCData.actualAccount = "";
				}
			};
			// 提交
			const fSubmit = () => {
				// 选中的提现方式
				const checked = oCPData.oYCData.checked;
				// 判断提现方式
				if (checked == 1) {
					// 支付宝
					// 账号
					if (!oZFB.account) {
						return Toast({
							message: tipsContent.emptyAccount,
							position: 'bottom'
						});
					}
					// 真实姓名
					if (!oZFB.realName) {
						return Toast({
							message: tipsContent.emptyRealName,
							position: 'bottom'
						});
					}
				} else {
					// 银行名称
					if (!oYH.bankName) {
						return Toast({
							message: tipsContent.emptyBankName,
							position: 'bottom'
						});
					}
					// 银行卡
					if (!oYH.account) {
						return Toast({
							message: tipsContent.emptyBankAccount,
							position: 'bottom'
						});
					}
					// 开户行
					if (!oYH.openingBank) {
						return Toast({
							message: tipsContent.emptyBankCardOpen,
							position: 'bottom'
						});
					}
					// 开户人姓名
					if (!oYH.openingPersonName) {
						return Toast({
							message: tipsContent.emptyBankCardName,
							position: 'bottom'
						});
					}
				}

				const tSMoney = sMoney.value;

				const tMinimum = oCPData.oNCData.minimum;
				const tMinimumText = oCPData.oNCData.minimumText.replace(/\/color(.*?)\/color/g, "$1");
				const tSingleMaxWithdrawalAmount = oCPData.oNCData.singleMaxWithdrawalAmount;
				const tSingleMaxWithdrawalAmountText = oCPData.oNCData.singleMaxWithdrawalAmountText;
				// 提现金额
				if (!tSMoney) {
					return Toast({
						message: tipsContent.emptyWithdrawalMoney,
						position: 'bottom'
					});
				} else if (tMinimum && Number(tSMoney) < Number(tMinimum)) {
					return Toast({
						message: tMinimumText ? tMinimumText : "最低提现金额" + tMinimum + "元",
						position: 'bottom'
					});
				} else if (tSingleMaxWithdrawalAmount && Number(tSMoney) > Number(tSingleMaxWithdrawalAmount)) {
					return Toast({
						message: tSingleMaxWithdrawalAmountText ? tSingleMaxWithdrawalAmountText : "最高提现金额" +
							tSingleMaxWithdrawalAmount +
							"元",
						position: 'bottom'
					});
				}

				oCPData.oYCData.disabled = true;
				Toast({
					type: "loading"
				});
				apiAccountCalculate({
					roleId: UserInfo.roleId,
					withdrawalAccount: tSMoney
				}).then(res => {
					oCPData.oYCData.actualAccount = res.result && res.result.actualAccount ? res.result
						.actualAccount : '0.00';
					if (Number(oCPData.oYCData.actualAccount) > 0) {
						let tData = checked == 1 ? fnDeepClone(oZFB) : fnDeepClone(oYH);

						return apiAccountWithdrawal({
							...tData,
							actualAmount: oCPData.oYCData.actualAccount,
							roleId: UserInfo.roleId,
							withdrawalAmount: tSMoney
						})
					} else {
						return new Promise((resolve, reject) => {
							console.log(resolve);
							reject({
								tMessage: "实际到账金额获取失败"
							});
						})
					}
				}).then(res => {
					// 按钮置灰
					oCPData.oYCData.disabled = false;
					if (res.success) {
						// 是否有待付佣金
						if (res.result.isHaveUnpay) {
							// 需要支付佣金  跳转到支付佣金界面
							fOpenDialog('oDialogB');
						} else {
							Toast({
								message: res.message ? res.message : "提现成功",
								position: 'bottom'
							});
							fLeft();
						}
					}
				}).catch(err => {
					if (err && err.tMessage) {
						Toast({
							message: err.tMessage,
							position: 'bottom'
						});
					}
					oCPData.oYCData.disabled = false;
				})
			};

			// 前往地址
			const fGoPath = (Path) => {
				if (Path === 'MyFollowOrderRecord') {
					router.push({
						path: Path,
						query: {
							redirect: 'moneyWithdraw'
						},
						params: {
							bReset: '0'
						}
					})
				} else if (Path === 'listBANK') {
					router.push({
						path: Path
					})
				}
			};


			// oDialogA
			const oDialogA = reactive({
				showDialog: false,
				showCancelButton: false,
				showConfirmButton: false
			});

			// oDialogB
			const oDialogB = reactive({
				showDialog: false,
				showCancelButton: false,
				confirmButtonText: "去支付佣金"
			});
			// 打开弹框
			const fOpenDialog = (type) => {
				if (type === "oDialogA") {
					oDialogA.showDialog = true;
				} else if (type === "oDialogB") {
					oDialogB.showDialog = true;
				}
			};
			// 弹框关闭之前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				console.log(action, oDialog, fn)
				if (dialogKey === "oDialogA") {
					if (action === "cancel") {
						oDialogA.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "oDialogB") {
					if (action === "confirm") {
						oDialogB.showDialog = false;
						fn(false);
						// 跳转支付佣金界面
						router.replace({
							path: '/recordGD',
							query: {
								redirect: 'mine'
							}
						})
					} else if (action === "cancel") {
						oDialogB.showDialog = false;
						fn(false);
					}
				}
			};
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			// 生命周期
			onActivated(() => {
				const route_meta = route.meta;
				console.log(route_meta.resetType)
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					store.dispatch('other/UWithdrawInfo', {});
					fGlobalReload();
				} else if (route_meta.resetType === '0') {
					fGetCPData();
				} else {
					oYH.bankName = store.state.other.withdrawInfo.bankName;
					route_meta.resetType = '1'
				}
			});
			return {
				oMyInfo,
				oZFB,
				oYH,
				oCPData,
				sMoney,


				oDialogA,
				oDialogB,
				fOpenDialog,
				fBeforeClose,
				fAllWithdraw,

				fUpdateMoney,
				fBlurMoney,

				fSubmit,
				fnFormatColor,
				isApp,
				fGoPath,
				fLeft
			}
		}
	}
</script>

<style scoped lang="scss">
	.withdraw {
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		box-sizing: border-box;

		.withdraw_tips {
			margin: 0.32rem auto;
			padding: 0.1333rem 0.32rem;
			text-align: center;
			background: rgba(255, 118, 113, 0.15);
			border-radius: 0.3466rem;
			border: 0.0266rem solid #FF7671;

			font-size: 0.32rem;
			font-weight: 400;
			color: #E2335D;

			display: flex;
		}

		.withdraw_body {
			margin: 0 0.2933rem;

			.withdraw_body_title {
				font-size: 0.4266rem;
				font-weight: 400;
				color: #383838;
			}

			.withdraw_body_a {
				background: #FFFFFF;
				border-radius: 0.2133rem;
				padding-bottom: 0.4266rem;

				.withdraw_body_a_title {
					padding: 0.4266rem;
					font-size: 0.4266rem;
					font-weight: 400;
					color: #383838;
				}

				.withdraw_body_a_a {
					display: flex;
					align-items: center;

					&>img {
						width: 0.96rem;
						height: auto;
						border-radius: 0.1066rem;
					}

					&>span {
						margin-left: 0.4266rem;
						font-size: 0.4266rem;
						font-weight: 400;
						color: #383838;
					}
				}
			}

			.withdraw_body_b {
				margin-top: 0.32rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background: #FFFFFF;
				border-radius: 0.2133rem;
				padding: 0.4266rem;

				.withdraw_body_b_a {
					font-size: 0.4266rem;
					font-weight: 400;
					color: #383838;
				}
			}

			.withdraw_body_c {
				padding: 0.4266rem;
				background: #FFFFFF;
				border-radius: 0.2133rem;

				.withdraw_body_c_a {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.withdraw_body_c_a_a {
						display: flex;
						align-items: center;

						&>img {
							width: 0.32rem;
							height: auto;
						}

						&>span {
							margin-left: 0.1066rem;
							font-size: 0.4266rem;
							font-weight: 400;
							color: #383838;
						}
					}

					.withdraw_body_c_a_b {
						font-size: 0.3733rem;
						font-weight: 400;
						color: #979797;

						em {
							color: #E2335D;
						}
					}
				}

				.withdraw_body_c_b {
					display: flex;
					align-items: center;
					margin-top: 0.4266rem;

					.withdraw_body_c_b_a {
						font-size: 0.6933rem;
						font-weight: bold;
						color: #383838;
						margin: 0 0.2133rem 0 0;
					}

					.withdraw_body_c_b_c {
						font-size: 0.32rem;
						font-weight: 500;
						color: #E2335D;
						margin-left: 0.4266rem;
					}
				}

				.withdraw_body_c_c {
					margin-top: 0.2933rem;
					margin-left: 0.8533rem;

					font-size: 0.3733rem;
					font-weight: 400;
					color: #979797;
				}
			}

			.withdraw_body_d {
				line-height: 0.48rem;
				white-space: break-spaces;
				word-break: break-all;
				margin-top: 0.32rem;
				font-size: 0.32rem;
				font-weight: 400;
				color: #979797;

				&>p {
					margin-bottom: 0.2133rem;
				}
			}
		}

		.withdraw_bottom {
			padding: 0.4266rem;
			background: #FFFFFF;
			border-radius: 0.2133rem 0.2133rem 0 0;
		}

		.withdraw_tips_dialog {
			padding: 0.2666rem 0.64rem 0.8rem;
			font-size: 0.4266rem;
			font-weight: 400;
			color: #383838;
			line-height: 0.5866rem;
		}

		.withdraw_tips_dialogB {
			padding: 0.2666rem 0.64rem 0.88rem;
			font-size: 0.4266rem;
			font-weight: 400;
			color: #383838;
			line-height: 0.5866rem;
		}
	}
</style>
