<template>
	<div class="about app_flex" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box;' : ''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="关于" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>

		<div class="about_body app_scroll">
			<img src="~@/assets/images/project/logo.png" alt="">
			<p class="about_body_a">红盈</p>
			<p class="about_body_b">理性购彩，量力而行</p>
			<p class="about_body_c">不向未满18周岁青少年出售彩票！</p>
			<span class="about_body_download" @click="fDownloadApp">下载APP</span>
		</div>
	</div>
</template>

<script>
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import Lockr from '@/utils/lockr';
	import {
		fnGetEnvironment,
		uaFun
	} from "@/utils/public.js";
	import config from "@/utils/config.js";
	export default {
		name: "About",
		setup() {
			const isApp = uaFun()
			const Environment = fnGetEnvironment();
			const fDownloadApp = () => {
				if (Environment === "Android") {
					window.location.href = config[process.env.VUE_APP_TITLE].download_Android +  '?subAppId=' + (Lockr.get('other/S_SUBAPPID') ? Lockr.get('other/S_SUBAPPID'): null);// 店铺ID;
				} else if (Environment === "IOS") {
					window.location.href = config[process.env.VUE_APP_TITLE].download_Android + '?subAppId=' + (Lockr.get('other/S_SUBAPPID') ? Lockr.get('other/S_SUBAPPID'): null);// 店铺ID;
				}
			}
			const fLeft = () => {
				fnIsHasHistory("/loginPw");
			};
			// 判断是否在app内


			return {
				fDownloadApp,
				fLeft,
				isApp
			}
		}
	}
</script>

<style scoped lang="scss">
	.about {
		width: 100%;
		height: 100%;
		box-sizing: border-box;

		.about_body {
			padding: 0 0.72rem 0.5333rem;
			text-align: center;

			&>img {
				padding-top: 2.6666rem;
				width: 2.1333rem;
				margin-bottom: 0.4rem;
			}

			&_a {
				font-size: 0.4rem;
				font-weight: bold;
				color: #333333;
				margin-bottom: 0.5333rem;
			}

			&_b {
				font-size: 0.5866rem;
				font-weight: bold;
				color: #333333;
				margin-bottom: 0.2933rem;
			}

			&_c {
				font-size: 0.4266rem;
				font-weight: bold;
				color: #666666;
			}

			&_download {
				display: inline-block;
				margin: 0.8rem 0.4rem 2.4rem;
				padding: 0.2666rem 0.4rem;
				background: linear-gradient(90deg, #F04A4A, #FE6F55);
				border-radius: 0.2133rem;

				font-size: 0.36rem;
				color: #FFFFFF;
			}
		}
	}
</style>
