<template>
	<div class="mine app_page app_flex">
		<div class="app_scroll mine_content" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box;' : ''">
			<div class="mine_content_top">
				<div class="top_l">
					<img class="top_img" :src="oMyInfo.head ? oMyInfo.head : defaultAvatar" alt="" @click="fGoPath('mineInfo')">
					<div class="top_name">
						<div class="name_top" @click="fGoPath('selectRole')">
							<div class="top_l">{{oMyInfo.roleName}}</div>
							<div class="top_r">更换角色</div>
							<img src="~@/assets/icon/icon-100.png" alt="">
						</div>
						<div class="name_bot">
							{{oMyInfo.accountNo}}
						</div>
					</div>
				</div>
				<div class="top_r">
					<div class="l" @click="fGoPath('notices')">
						<img src="~@/assets/icon/icon-101.png" alt="">
						<div v-show="oCPData.hasUnreadNotice"></div>
					</div>
					<div class="r" @click="fGoWeb('lotteryShop', oMyInfo.lotteryShopUrl)">{{oMyInfo.shopName}}</div>
				</div>
			</div>
			<div class="mine_content_img">
				<div class="img_box">
					<div class="img_top">
						<div class="top">
							<div class="top_title">
								<p @click="fGoPath('capitalAll')">
									店内总金额
								</p>
								<img v-show="oCPData.eyeShow" src="~@/assets/icon/icon-102.png" alt=""
									@click="fChangeEyeShow(false)">
								<img v-show="!oCPData.eyeShow" src="~@/assets/icon/icon-111.png" alt=""
									@click="fChangeEyeShow(true)">
							</div>
							<div class="top_q">
								<div class="top_q_l" @click="fGoPath('capitalAll')">
									<span v-show="oCPData.eyeShow" style="font-size: 0.373rem;">￥</span>
									<span v-show="oCPData.eyeShow">{{oMyInfo.totalAmount}}</span>
									<span v-show="!oCPData.eyeShow">******</span>
								</div>
								<div class="top_q_r">
									<p class="cz" @click="fGoPath('recharge')">充值</p>
									<p class="tx" @click="fGoPath('withdraw')">提现</p>
								</div>
							</div>
						</div>
						<div class="bottom">
							<div class="bot_l" @click="fGoPath('capitalStore')">
								<span>店内余额</span>
								<span v-show="oCPData.eyeShow"
									style="margin: 0 0.08rem 0 0.213rem;font-size: 0.267rem;font-weight: bold;">¥</span>
								<span v-show="oCPData.eyeShow" style="font-size: 0.453rem;font-weight: bold;">
									{{oMyInfo.availableAmount ? oMyInfo.availableAmount : "0.00"}}
								</span>
								<span style="margin: 0 0.08rem 0 0.213rem;font-size: 0.453rem;font-weight: bold;" v-show="!oCPData.eyeShow">
									******
								</span>
							</div>
							<div class="bot_r" @click="fGoPath('capitalFrozen')">
								<span style="margin-right: 0.133rem;">
									冻结
								</span>
								<span v-show="oCPData.eyeShow">
									{{oMyInfo.freezeAmount ? oMyInfo.freezeAmount : '0.00'}}
								</span>
								<span v-show="!oCPData.eyeShow">
									******
								</span>
							</div>
						</div>
					</div>
					<div class="img-bottom">
						<div class="bot_l" @click="fGoPath('capitalExtraction')">
							<div class="bot_l_t">
								<img src="~@/assets/icon/icon-103.png" alt="" @click.stop="fOpenCommissionExtractExplain">
								<p class="bot_l_t1">待提佣金</p>
								<p class="bot_l_t2"
									:class="{'bot_l_t2--disabled' : Number(oMyInfo.unReceiveCommission)<=0}"
									@click.stop="fOpenExtract">提到余额</p>
							</div>
							<div class="bot_l_b" v-show="oCPData.eyeShow">
								¥{{oMyInfo.unReceiveCommission ? oMyInfo.unReceiveCommission : '0.00'}}
							</div>
							<div class="bot_l_b" v-show="!oCPData.eyeShow">
								******
							</div>
						</div>
						<div class="bot_l bot_r" @click="fGoPath('recordGD')">
							<div class="bot_l_t">
								<img src="~@/assets/icon/icon-103.png" alt="" @click.stop="fOpenCommissionPaymentExplain">
								<p class="bot_l_t1">待付佣金</p>
								<p class="bot_l_t2" :class="{'bot_l_t2--disabled' : Number(oMyInfo.unPayCommission)<=0}"
									@click.stop="fOpenPay">立即支付</p>
							</div>
							<div class="bot_l_b" v-show="oCPData.eyeShow">
								¥{{oMyInfo.unPayCommission ? oMyInfo.unPayCommission : '0.00'}}
							</div>
							<div class="bot_l_b" v-show="!oCPData.eyeShow">
								******
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mine_content_list">
				<div class="list_item" @click="fGoPath('guanzhu')">
					<div class="list_item_left">
						<img src="~@/assets/icon/icon-107.png" alt="">
						<p>关注</p>
					</div>

					<div class="list_item_right">
						<img src="~@/assets/icon/icon-007.png" alt="">
					</div>
				</div>
				<div class="list_item" @click="fGoPath('recordFD')">
					<div class="list_item_left">
						<img src="~@/assets/icon/icon-104.png" alt="">
						<p>发单记录</p>
					</div>
					<div class="list_item_right">
						<img src="~@/assets/icon/icon-007.png" alt="">
					</div>
				</div>
				<div class="list_item" @click="fGoPath('recordGD')">
					<div class="list_item_left">
						<img src="~@/assets/icon/icon-105.png" alt="">
						<p>跟单记录</p>
					</div>
					<div class="list_item_right">
						<img src="~@/assets/icon/icon-007.png" alt="">
					</div>
				</div>
				<div class="list_item" @click="fGoPath('recordHB')">
					<div class="list_item_left">
						<img src="~@/assets/icon/icon-106.png" alt="">
						<p>红包记录</p>
					</div>

					<div class="list_item_right">
						<img src="~@/assets/icon/icon-007.png" alt="">
					</div>
				</div>
				<div class="list_item" @click="fCopyQQ()">
					<div class="list_item_left">
						<img src="~@/assets/icon/icon-107.png" alt="">
						<p>联系店主</p>
					</div>

					<div class="list_item_right">
						<span>{{oMyInfo.contact}}</span>
						<img src="~@/assets/icon/icon-007.png" alt="">
					</div>
				</div>
				<div class="list_item" @click="fGoPath('setup')">
					<div class="list_item_left">
						<img src="~@/assets/icon/icon-108.png" alt="">
						<p>设置</p>
					</div>
					<div class="list_item_right">
						<img src="~@/assets/icon/icon-007.png" alt="">
					</div>
				</div>
			</div>
		</div>


		<h-dialog-a title="待提佣金说明" dialogKey="dialogA" :show="oCPData.oYCData.dialogA.showDialog"
			:showCancelButton="oCPData.oYCData.dialogA.showCancelButton"
			:showConfirmButton="oCPData.oYCData.dialogA.showConfirmButton"
			:confirmButtonText="oCPData.oYCData.dialogA.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="mine_tips_b">
				待提佣金是收到跟单佣金还未提到店内余额的佣金
			</div>
		</h-dialog-a>
		<h-dialog-a title="待付佣金说明" dialogKey="dialogB" :show="oCPData.oYCData.dialogB.showDialog"
			:showCancelButton="oCPData.oYCData.dialogB.showCancelButton"
			:showConfirmButton="oCPData.oYCData.dialogB.showConfirmButton"
			:confirmButtonText="oCPData.oYCData.dialogB.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="mine_tips_b">
				待付佣金是在跟单方案中奖后，中奖金额满足支付佣金条件后，需要支付给发单者的佣金
			</div>
		</h-dialog-a>
		<h-dialog-c title="提到余额" dialogKey="dialogC" :show="oCPData.oYCData.dialogC.showDialog"
			:showCancelButton="oCPData.oYCData.dialogC.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogC.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="mine_tips_a">
				<p class="mine_tips_a_a">待提佣金：<em>￥{{oTurnBalance.unReceiveCommission}}</em></p>
				<p class="mine_tips_a_a" v-if="oTurnBalance.platformCost">平台手续：<em>{{oTurnBalance.platformCost}}</em>
				</p>
				<p class="mine_tips_a_b">系统将按照《发单协议》扣除佣金后，将剩余佣金提到您的店内余额中。</p>
			</div>
		</h-dialog-c>
		<h-dialog-c title="支付成功" dialogKey="dialogD" :show="oCPData.oYCData.dialogD.showDialog"
			:showCancelButton="oCPData.oYCData.dialogD.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogD.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="mine_tips_c">
				跟单待付佣金已结清！
			</div>
		</h-dialog-c>
		<!-- <h-dialog-c title="提到余额" dialogKey="dialogD" :show="oCPData.oYCData.dialogD.showDialog"
			:showCancelButton="oCPData.oYCData.dialogD.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogD.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="mine_tips_a">
				<p class="mine_tips_a_a">待提佣金：<em>￥599.00</em></p>
				<p class="mine_tips_a_a">实际到账：<em>￥399.00</em></p>
				<p class="mine_tips_a_b">系统将按照《发单协议》扣除佣金后，将剩余佣金提到您的店内余额中。</p>
			</div>
		</h-dialog-c> -->
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		onActivated,
		inject
	} from "vue";
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import {
		fnCopyInnerText,
		uaFun
	} from '@/utils/public.js';
	import tipsContent from '@/utils/tipsContent';
	import {
		apiGetUserInfo,
		apiGetNoticeHasUnread,
		apiTurnBalance,
		apiImmediatelyPay
	} from '@/api/mine.js';
	import HDialogA from "@/components/HDialog/HDialogA/index.vue";
	import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	
	import defaultAvatar from "@/assets/images/img_032.png";
	export default {
		name: "Mine",
		components: {
			HDialogA,
			HDialogC
		},
		setup() {
			const isApp = uaFun()
			const route = useRoute();
			const router = useRouter(); //初始化路由
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			const fGlobalReload = inject("fGlobalReload");
			// 我的信息
			const oMyInfo = ref(store.state.userInfo.myInfo);
			// 获取我的信息
			const fGetUserInfo = () => {
				Toast({
					type: "loading"
				});
				apiGetUserInfo({
					roleId: UserInfo.roleId,
					userId: UserInfo.userId
				}).then(res => {
					oMyInfo.value = res && res.result ? res.result : {};
					store.dispatch("userInfo/UMyInfo", oMyInfo.value);
					UserInfo.isSync = oMyInfo.value.isSync ? true : false;
					store.dispatch("userInfo/UUserInfo", UserInfo);
				})
			};
			// 当前页面数据
			const oCPData = reactive({
				hasUnreadNotice: false,
				eyeShow: store.state.other.eyeShow,
				oYCData: {
					dialogA: {
						showDialog: false,
						showCancelButton: false,
						showConfirmButton: false
					},
					dialogB: {
						showDialog: false,
						showCancelButton: false,
						showConfirmButton: false
					},
					dialogC: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					},
					dialogD: {
						showDialog: false,
						showCancelButton: false,
						confirmButtonText: "确定"
					},
				}
			})
			// 查询是否有未读消息
			const fGetNoticeHasUnread = () => {
				apiGetNoticeHasUnread({
					id: UserInfo.roleId,
					userId: UserInfo.userId
				}).then(res => {
					oCPData.hasUnreadNotice = res && res.result ? res.result.hasUnreadNotice : false;
				})
			};

			// 前往地址
			const fGoPath = (Path) => {
				console.log(Path);
				if ( Path === "recharge" && oMyInfo.value.rechargeWay === "QRCODE") {
					router.push({
						path: '/web',
						query: {
							type: "recharge",
							url: oMyInfo.value.rechargeUrl
						}
					});
				} else {
					router.push({
						path: '/' + Path,
						query: {
							redirect: "mine"
						}
					});
				}
			};
			// 前往web页面
			const fGoWeb = (Web, Url) => {
				if (Url) {
					router.push({
						path: '/web',
						query: {
							type: Web,
							url: Url
						}
					});
				} else {
					router.push({
						path: '/web',
						query: {
							type: Web
						}
					});
				}
			};
			// 复制联系方式
			const fCopyQQ = () => {
				let tData = fnCopyInnerText("2963252414");
				if (tData) {
					Toast({
						message: tipsContent.copySuc,
						position: 'bottom'
					});
				} else {
					Toast({
						message: tipsContent.copyFail,
						position: 'bottom'
					});
				}
			};
			// 小眼睛开关
			const fChangeEyeShow = (TF) => {
				oCPData.eyeShow = TF;
				store.dispatch('other/UEyeShow', TF);
			};

			// 打开--待提佣金说明窗口
			const fOpenCommissionExtractExplain = () => {
				oCPData.oYCData.dialogA.showDialog = true;
			};
			// 打开--待付佣金说明窗口
			const fOpenCommissionPaymentExplain = () => {
				oCPData.oYCData.dialogB.showDialog = true;
			};
			// 打开--提到余额窗口
			const fOpenExtract = () => {
				fTurnBalance(1);
			};
			// 提到余额详情
			const oTurnBalance = ref({});
			// 提到余额(1：获取详情，2：确定提到余额)
			const fTurnBalance = (type, fn) => {
				if (Number(oMyInfo.value.unReceiveCommission) <= 0) {
					return
				}
				let tData = null;
				if (type === 1) {
					tData = {
						type: 1,
						unReceiveCommission: oMyInfo.value.unReceiveCommission
					}
				} else if (type === 2) {
					tData = {
						type: 2,
						unReceiveCommission: oTurnBalance.value.unReceiveCommission
					}
				}
				Toast({
					type: "loading"
				});
				// 获取弹框信息
				apiTurnBalance({
					...tData,
					roleId: UserInfo.roleId,
					userId: UserInfo.userId
				}).then(res => {
					let tData = res.result ? res.result : {};
					oTurnBalance.value = tData;
					if (type === 1) {
						oCPData.oYCData.dialogC.showDialog = true;
					} else if (type === 2) {
						fGetUserInfo();
						Toast({
							message: '已成功提到店内余额！',
							position: 'bottom'
						});
						fn(false)
					}
				})
			};

			// 打开--立即支付窗口
			const fOpenPay = () => {
				if (oMyInfo.value.unPayCommission <= 0) {
					return
				}
				// 获取弹框信息
				apiImmediatelyPay({
					roleId: UserInfo.roleId,
					userId: UserInfo.userId,
					unPayCommission: oMyInfo.value.unPayCommission
				}).then(() => {
					fGetUserInfo();
					oCPData.oYCData.dialogD.showDialog = true;
				})
			};

			// 弹框关闭之前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				console.log(action, oDialog, fn)
				if (dialogKey === "dialogA") {
					if (action === "cancel") {
						oCPData.oYCData.dialogA.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogB") {
					if (action === "cancel") {
						oCPData.oYCData.dialogB.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogC") {
					if (action === "confirm") {
						oCPData.oYCData.dialogC.showDialog = false;
						fTurnBalance(2, fn);
					} else if (action === "cancel") {
						oCPData.oYCData.dialogC.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogD") {
					if (action === "confirm") {
						oCPData.oYCData.dialogD.showDialog = false;
						fn(false)
					} else if (action === "cancel") {
						oCPData.oYCData.dialogD.showDialog = false;
						fn(false)
					}
				}
			};



			// 生命周期
			onActivated(() => {
				console.log("已经进入mine页面")
				const route_meta = route.meta;
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fGlobalReload();
				} else if (route_meta.resetType === '0') {
					route_meta.resetType = '1'
					fGetUserInfo();
					fGetNoticeHasUnread();
				}
			});
			return {
				oMyInfo,
				oCPData,


				fChangeEyeShow,

				oTurnBalance,
				fOpenCommissionExtractExplain,
				fOpenCommissionPaymentExplain,
				fOpenExtract,
				fOpenPay,
				fBeforeClose,

				fCopyQQ,
				fGoPath,
				fGoWeb,
				defaultAvatar,
				isApp
			}
		}
	}
</script>

<style scoped lang="scss">
	.mine {
		width: 100%;
		height: 100%;
		background: #ffffff;
		background-image: url('~@/assets/images/img_100.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
	}

	.mine_content {
		width: 100%;
		height: 100%;
		padding: 0.3733rem 0.293rem 1.84rem;
		box-sizing: border-box;

		.mine_content_top {
			width: 100%;
			height: 1.333rem;
			padding-left: 0.133rem;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;

			.top_l {
				display: flex;

				.top_img {
					width: 1.28rem;
					height: 1.28rem;
					background: rgba(158, 97, 30, 0.15);
					border-radius: 50%;
					margin-right: 0.213rem;
				}

				.top_name {
					padding-top: 0.16rem;
					box-sizing: border-box;

					.name_top {
						display: flex;
						align-items: center;
						margin-bottom: 0.267rem;

						.top_l {
							font-size: 0.427rem;
							font-weight: 600;
							color: #9E611E;
							margin-right: 0.32rem;
						}

						.top_r {
							font-size: 0.267rem;
							font-weight: 600;
							color: #9E611E;
							margin-right: 0.107rem;
						}

						img {
							width: 0.16rem;
							height: 0.267rem;
						}
					}

					.name_bot {
						font-size: 0.267rem;
						font-weight: 400;
						color: #9E611E;
					}
				}
			}

			.top_r {
				display: flex;
				justify-content: space-between;
				flex-direction: column;

				.l {
					display: flex;
					justify-content: flex-end;
					position: relative;

					div {
						width: 0.107rem;
						height: 0.107rem;
						background: #E2335D;
						position: absolute;
						right: -0.08rem;
						top: -0.08rem;
						border-radius: 50%;
					}

					img {
						width: 0.453rem;
						height: 0.4rem;
					}
				}

				.r {
					padding: 0 0.08rem;
					box-sizing: border-box;
					height: 0.453rem;
					background: linear-gradient(270deg, #DCAD83 0%, #9E611E 100%);
					border-radius: 0px 0.213rem 0px 0.213rem;
					line-height: 0.453rem;
					font-size: 0.267rem;
					font-weight: 400;
					color: #FFFFFF;
					text-align: center;
				}
			}
		}

		.mine_content_img {
			width: 100%;
			height: 6rem;
			background-image: url('~@/assets/images/img_101.png');
			background-size: 100% 6rem;
			background-repeat: no-repeat;

			.img_box {
				width: 100%;
				height: 100%;
				padding-top: 0.373rem;
				box-sizing: border-box;

				.img_top {
					width: 100%;
					height: 3.6rem;

					.top {
						width: 100%;
						height: 2.24rem;
						padding: 0.48rem 0.4rem;
						box-sizing: border-box;

						.top_title {
							width: 100%;
							height: 0.533rem;
							display: flex;
							align-items: center;

							p {
								font-size: 0.373rem;
								font-weight: 500;
								color: #FFFFFF;
								margin-right: 0.213rem;
							}

							img {
								width: 0.373rem;
								height: 0.213rem;
							}
						}

						.top_q {
							width: 100%;
							height: 0.747rem;
							display: flex;
							justify-content: space-between;

							span {
								font-size: 0.64rem;
								font-weight: bold;
								color: #FFFFFF;
								line-height: 0.747rem;
							}

							.top_q_r {
								display: flex;
								align-items: center;
								font-weight: 500;

								p {
									width: 1.333rem;
									height: 0.533rem;
									border-radius: 0.427rem;
									line-height: 0.587rem;
									text-align: center;
								}

								.cz {
									background: #EEC89D;
									color: #7B460D;
									margin-right: 0.213rem;
								}

								.tx {
									border: 0.027rem solid #FFFFFF;
									color: #FFFFFF;
								}
							}
						}
					}

					.bottom {
						width: 100%;
						height: 1.36rem;
						padding-right: 0.587rem;
						box-sizing: border-box;
						display: flex;
						justify-content: space-between;
						align-items: center;

						.bot_l {
							height: 0.667rem;
							background: #FBE9DB;
							border-radius: 0px 0.347rem 0.347rem 0px;
							padding: 0 0.4rem;
							box-sizing: border-box;
							text-align: center;
							font-size: 0.32rem;
							font-weight: 400;
							color: #9E611E;
							display: flex;

							span {
								height: 100%;
								line-height: 0.72rem;
							}
						}

						.bot_r {
							height: 0.667rem;
							font-size: 0.347rem;
							font-weight: bold;
							color: #FFFFFF;
							line-height: 0.667rem;
						}
					}
				}

				.img-bottom {
					padding: 0.267rem 0.4266rem;
					box-sizing: border-box;
					display: flex;
					justify-content: center;

					.bot_l {
						width: 50%;
						padding-left: 0.267rem;
						border-right: 0.027rem solid #EBEBEB;

						.bot_l_t {
							display: flex;
							align-items: center;
							margin-bottom: 0.133rem;

							img {
								width: 0.267rem;
								height: 0.267rem;
							}

							.bot_l_t1 {
								margin: 0 0.213rem 0 0.107rem;
								font-size: 0.32rem;
								font-weight: 500;
								color: #383838;
								white-space:nowrap;
							}

							.bot_l_t2 {
								border-radius: 0.107rem;
								border: 0.027rem solid #E2335D;
								font-size: 0.293rem;
								font-weight: 500;
								color: #E2335D;
								text-align: center;
								padding: 0.053rem 0.133rem;
								box-sizing: border-box;
								white-space:nowrap;
							}

							.bot_l_t2--disabled {
								color: #979797;
								border: 0.027rem solid #979797;
							}
						}

						.bot_l_b {
							width: 100%;
							padding-left: 0.4rem;
							box-sizing: border-box;
							font-size: 0.4rem;
							font-weight: bold;
							color: #E2335D;
						}
					}

					.bot_r {
						width: 50%;
						border: none;
						padding-left: 0.267rem;
						box-sizing: border-box;
					}
				}
			}

		}

		.mine_content_list {
			margin: 0 0.417rem;

			.list_item {
				width: 100%;
				height: 1.493rem;
				border-bottom: 0.027rem solid #F5F5F5;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.list_item_left {
					display: flex;
					align-items: center;

					&>img {
						width: 0.693rem;
						height: 0.693rem;
						margin-right: 0.213rem;
					}

					&>p {
						font-size: 0.373rem;
						font-weight: 400;
						color: #383838;

					}
				}

				.list_item_right {
					display: flex;
					align-items: center;

					&>span {
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.4266rem;
						margin-right: 0.2133rem;
					}

					&>img {
						width: 0.16rem;
						height: 0.267rem;
					}
				}
			}
		}
	}

	.mine_tips_a {
		padding: 0.8rem 0.64rem;

		.mine_tips_a_a {
			font-size: 0.4266rem;
			font-weight: bold;
			color: #383838;
			line-height: 0.5866rem;

			&>em {
				font-weight: bold;
				color: #E2335D;
			}
		}

		.mine_tips_a_b {
			font-size: 0.3733rem;
			font-weight: 400;
			color: #979797;
			line-height: 0.5866rem;
		}
	}

	.mine_tips_b {
		padding: 0.2666rem 0.8533rem;
		font-size: 0.4266rem;
		font-weight: 400;
		color: #383838;
		line-height: 0.5866rem;
	}

	.mine_tips_c {
		text-align: center;
		padding: 0.2666rem 0.8533rem;
		font-size: 0.4266rem;
		font-weight: 400;
		color: #383838;
		line-height: 0.5866rem;
	}
</style>
